import { get } from 'lodash';
import { ActorsConstants } from '../../actors/constants/actionTypes';

const initialState = {
  fetching: false,
  data: [],
  error: '',
};

export default (state = initialState, action = {}) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case ActorsConstants.SERVICE_SPECIFIC_ADMIN_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case ActorsConstants.SERVICE_SPECIFIC_ADMIN_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: payload,
      };

    case ActorsConstants.SERVICE_SPECIFIC_ADMIN_FAILURE:
      return {
        ...state,
        fetching: false,
        error: '',
        data: [],
      };
    default:
      return state;
  }
};

export const getServiceAdmin = (state) => get(state, 'actorInfo.serviceAdmin.data.is_service_admin', false);

export const getServiceAdminFetching = (state) => get(state, 'actorInfo.serviceAdmin.fetching', true);
