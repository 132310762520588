import PropTypes from 'prop-types';
import React, {
  memo, useContext, useEffect, useState,
} from 'react';
import { Button, Modal } from 'antd';
import _ from 'lodash';

import ListOfPublicInterfacesTable from './ListOfPublicInterfacesTable';

import useCustomState from '../../hooks/useCustomState';
import { ApiContext } from '../../api/ApiContextProvider';
import { antNotification } from '../../mainUtils';

function AddPublicInterfaceToGroup({
  actorUUID,
  onSaveCallback,
  defaultRowSelection = [],
}) {
  const {
    requestGetAllInterfaces,
    requestGetOpenInterfaces,
    requestSetPublicInterfaceForActor,
  } = useContext(ApiContext);

  const [dataInterfaces, setDataInterfaces] = useState([]);
  const [rowSelection, setRowSelection] = useState(defaultRowSelection);

  const {
    toggle,
    setToggle,
  } = useCustomState();

  const onCancel = () => {
    setToggle();
  };

  const onSave = () => {
    requestSetPublicInterfaceForActor({
      actorUUID,
      publicInterfacesIDs: rowSelection.map((item) => +item),
    }).then(() => {
      antNotification.defaultSuccess();

      onSaveCallback?.();
    });
    setToggle();
  };

  const onRow = (row) =>
    // console.log('row row', row);
    ({
      onClick: (e) => {
        e.stopPropagation();

        const rowId = row.id.toString();

        if (rowSelection.includes(rowId)) {
          setRowSelection(rowSelection.filter((item) => +item !== +row.id));
        } else {
          setRowSelection([...rowSelection, rowId]);
        }
      },
    }
    );
  const getAllInterfaces = () => new Promise((resolve) => {
    requestGetAllInterfaces().then((response) => {
      requestGetOpenInterfaces().then();
      resolve(_.get(response, 'public_interface', []));
    });
  });

  const initFunc = () => {
    getAllInterfaces().then((data) => {
      setDataInterfaces(data);
    });
  };

  useEffect(() => {
    if (toggle) {
      initFunc();
    } else {
      setRowSelection([]);
    }
  }, [toggle]);

  useEffect(() => {
    setRowSelection(defaultRowSelection);
  }, [defaultRowSelection.length]);

  return (
    <>
      <Button
        className="button-primary-outlined"
        onClick={setToggle}
      >
        Add public interface
      </Button>
      <Modal
        title="Public interfaces"
        open={toggle}
        onOk={setToggle}
        onCancel={onCancel}
        destroyOnClose
        width="40%"
        centered
        footer={[
          <Button
            key="back"
            onClick={onCancel}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={onSave}
            // disabled={!localActorsIsChanged}
          >
            Save
          </Button>,
        ]}
      >
        <ListOfPublicInterfacesTable
          tableData={dataInterfaces}
          selection={rowSelection}
          onRow={onRow}
          hiddenElements={['delete', 'display']}
        />
      </Modal>
    </>
  );
}

export default memo(AddPublicInterfaceToGroup);

AddPublicInterfaceToGroup.propTypes = {
  actorUUID: PropTypes.string,
  defaultRowSelection: PropTypes.array,
  onSaveCallback: PropTypes.func,
};
