import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import _ from 'lodash';

import configureStore from './store';
import enDict from './locale/dictionaries/en.json';
import ruDict from './locale/dictionaries/ru.json';

export const { store } = configureStore();

const initialLanguage = _.get(store.getState(), 'locales.language', 'en');

i18next
  .use(initReactI18next)
  .init({
    lng: initialLanguage,
    debug: false,
    fallbackLng: initialLanguage,
    whitelist: ['en', 'ru'],
    resources: {
      en: {
        translation: enDict,
      },
      ru: {
        translation: ruDict,
      },
    },
  })
  .then();

export default i18next;
