import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { mdiCancel } from '@mdi/js';

import AntButtonWithPopConfirm from '../../components/AntButtonWithPopConfirm';

import useAuthActorsRequests from '../hooks/useAuthActorsRequests';
import { antNotification } from '../../mainUtils';
import { getDefaultBanGroupUUID } from '../selectors';

function AddToBanGroupBtn({
  className,
  actor,
  onSaveCallBack,
}) {
  const { requestUpdateActorOnAuth } = useAuthActorsRequests();

  const defaultGroupUUID = useSelector(getDefaultBanGroupUUID);

  const {
    actor_type: actorType,
    uuid,
    uinfo: {
      groups,
    } = {},
  } = actor || {};

  const actorIsBanned = groups?.includes(defaultGroupUUID);

  const btnOnClick = () => {
    requestUpdateActorOnAuth({
      actor_type: actorType,
      add_actors_list: actorIsBanned ? [] : [defaultGroupUUID],
      remove_actors_list: actorIsBanned ? groups : [],
      uuid,
    }).then(() => {
      antNotification.defaultSuccess();

      onSaveCallBack?.();
    });
  };

  return (
    <AntButtonWithPopConfirm
      popConfirmTitle="Are you sure?"
      onClick={btnOnClick}
      size="small"
      pathForMdi={mdiCancel}
      className={`button-warning-outlined ${className}`}
      label={`${actorIsBanned ? 'Unban' : 'Ban'} actor`}
    />
  );
}

export default AddToBanGroupBtn;

AddToBanGroupBtn.propTypes = {
  actor: PropTypes.any,
  className: PropTypes.string,
  onSaveCallBack: PropTypes.func,
};
