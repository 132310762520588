import { combineReducers } from 'redux';

import fetchProfileInfoReducer from '../profile/reducers/fetchProfileInfoReducer';
import getListOfPoliciesReducer from '../securityPolicy/reducers/getListOfPoliciesReducer';
import actorsDataReducer from '../actors/reducers/actorsDataReducer';
import actorsInfoReducer from '../actors/reducers/actorsInfoReducer';
import permissionsListReducer from '../permissions/reducers/permissionsListReducer';
import publicUIReducer from '../publicInterfaces/reducers';
import locales from '../locale/reducers';
import filtersReducer from '../actors/reducers/filtersReducer';
import createActorReducer from '../actors/reducers/createActorReducer';
import authInfoReducer from '../auth/reducers/authInfoReducer';
import gertCertificatesReducer from '../certificates/reducers/gertCertificatesReducer';
import syncServicesInfoReducer from '../syncServices/reducers/syncServicesInfoReducer';
import adminServicePermSlicer from '../permissions/slicers/adminServicePermSlicer';
import serviceVersioningReducer from '../actors/reducers/serviceVersioningReducer';
import servicesKeyPairsReducer from '../actors/reducers/servicesKeyPairsReducer';
import actorsMapReducer from '../actors/reducers/actorsMapReducer';

const createRootReducer = combineReducers({
  // router: connectRouter(history),
  auth: combineReducers({
    info: authInfoReducer,
  }),
  actors: combineReducers({
    listOfActors: actorsDataReducer,
    created: createActorReducer,
    serviceVersioning: serviceVersioningReducer,
    servicesKeyPairs: servicesKeyPairsReducer,
    actorsMapReducer,
    type: 'user',
  }),
  actorInfo: combineReducers({
    data: actorsInfoReducer,
    permissions: permissionsListReducer,
    serviceAdmin: adminServicePermSlicer,
  }),
  certificates: gertCertificatesReducer,
  filter: filtersReducer,
  locales,
  policy: getListOfPoliciesReducer,
  profile: combineReducers({
    info: fetchProfileInfoReducer,
  }),
  publicUI: combineReducers({
    interfacesList: publicUIReducer,
  }),
  syncServices: syncServicesInfoReducer,
});

const rootReducer = (state, action) => createRootReducer(state, action);

export default rootReducer;
