import React, { memo } from 'react';
import { Button, Popconfirm } from 'antd';
import Icon from '@mdi/react';

const AntButtonWithPopConfirm = memo(({
  className,
  disabled,
  type,
  pathForMdi,
  sizeMdi = 1,
  label,
  onClick,
  iconClassName,
  size,
  popConfirmTitle,
  popConfirmPlacement = 'top',
  popConfirmColor,
}) => (
  <Popconfirm
    placement={popConfirmPlacement}
    title={popConfirmTitle}
    onConfirm={onClick}
    okText="Yes"
    cancelText="No"
    color={popConfirmColor}
  >
    <Button
      type={type}
      className={className}
      size={size}
      disabled={disabled}
      icon={pathForMdi && (
        <Icon
          className={iconClassName}
          path={pathForMdi}
          size={sizeMdi}
        />
      )}
    >
      {label}
    </Button>
  </Popconfirm>
));

export default AntButtonWithPopConfirm;
