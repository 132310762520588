import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { Badge } from 'antd';

import { upperFirst } from 'lodash';
import ActorsModalBtn from './ActorsModalBtn';
import useCustomState from '../../hooks/useCustomState';

function AddActorsForActor({
  btnModalLabel,
  onSaveCallBack,
  actorData,
  editMode,
  hideElements = [],
  hideModalElements,
  iconModalPath,
}) {
  const defaultState = {
    localActors: null,
  };

  const {
    changeState,
    localActors,
  } = useCustomState(defaultState);

  const {
    actor_type: actorType,
    uuid: actorUUID,
    uinfo: {
      groups = [],
    } = {},
  } = actorData || {};

  const resetValue = useMemo(() => !editMode && new Date().getTime(), [editMode]);

  const onSaveCallBackAndSetLocalActors = (actors) => {
    changeState({
      localActors: actors.selectedActors?.map((item) => item.uuid),
    });

    onSaveCallBack?.(actors);
  };

  const doNotMakeRequest = editMode ? ['saveActors'] : [];

  const getLabelForBtn = () => {
    switch (actorType) {
      case 'user':
      case 'classic_user':
        return 'Add/remove groups';
      case 'group':
        return 'Add/remove users';
      case 'service':
        return '+/- groups';
      default:
        return 'Add/remove actors';
    }
  };

  useEffect(() => {
    if (!editMode) {
      changeState(defaultState);
    }
  }, [editMode]);

  return (
    <Badge count={editMode ? localActors?.length : null}>
      <ActorsModalBtn
        actorType={editMode ? `edit${upperFirst(actorType)}` : actorType}
        actorUUID={actorUUID}
        btnModalLabel={btnModalLabel || getLabelForBtn()}
        initialActorsUUIDs={localActors || groups}
        outerActorsUUIDs={localActors}
        hideElements={hideModalElements}
        iconPath={iconModalPath}
        listOfActorsActionBtnLabel="add group"
        onSaveCallBack={onSaveCallBackAndSetLocalActors}
        doNotMakeRequest={doNotMakeRequest}
        resetFromOuterComponent={resetValue}
      />
    </Badge>
  );
}

export default AddActorsForActor;

AddActorsForActor.propTypes = {
  btnModalLabel: PropTypes.string,
  hideElements: PropTypes.array,
  onSaveCallBack: PropTypes.func,
};
