import PropTypes from 'prop-types';
import React, { useContext, useReducer } from 'react';
import { mdiCloudCheckOutline } from '@mdi/js';

import { ApiContext } from '../../api/ApiContextProvider';
import { antNotification } from '../../mainUtils';
import AntButtonWithMDI from '../../components/AntButtonWithMDI';

function CheckKeyPairOfServiceBtn({
  serviceUUID,
}) {
  const { requestCheckServiceKeyPair } = useContext(ApiContext);

  const [loading, setLoading] = useReducer((state) => !state, false);

  const checkKeys = () => {
    setLoading();
    requestCheckServiceKeyPair(serviceUUID)
      .then((res) => {
        const {
          database,
          settings,
          valid_key_pair,
        } = res || {};

        setLoading();

        if (database && settings && valid_key_pair) {
          antNotification.success('Key pair is valid');
        }

        if (!database) {
          antNotification.warning('Public key does not matches with key in service database!');
        }

        if (!settings) {
          antNotification.warning('Public key does not matches with key in service settings!');
        }

        if (!valid_key_pair) {
          antNotification.warning('Key pair is not valid!');
        }
      })
      .catch(({ message }) => {
        setLoading();
      });
  };

  return (
    <AntButtonWithMDI
      disabled={loading}
      onClick={checkKeys}
      type="default"
      className="button-primary-outlined w-100"
      pathForMdi={mdiCloudCheckOutline}
      label={loading ? 'Loading...' : 'Check key pair'}
    />
  );
}

export default CheckKeyPairOfServiceBtn;

CheckKeyPairOfServiceBtn.propTypes = {
  serviceUUID: PropTypes.string,
};
