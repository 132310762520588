import { AuthHelper } from '../../auth/utils';
import { antNotification } from '../../mainUtils';

export const unauthorized = (errorMessage) => {
  try {
    AuthHelper.removeSessionToken('Auth');

    window.location.href = '/login';

    antNotification.error(errorMessage, 'Warning');
  } catch (e) {
    // console.log('Error');
  }
};

export const logout = () => {
  try {
    AuthHelper.clearCookies();

    const currentLocale = localStorage.getItem('locale') || 'en';

    localStorage.clear();
    localStorage.setItem('locale', currentLocale);

    window.location.href = '/login';
  } catch (e) {
    // console.log('Error');
  }
};
