import { Outlet } from 'react-router-dom';
import React, { useEffect } from 'react';
import { Breadcrumb } from 'antd';
import { capitalizeAndTranslateMsg } from '../../mainUtils';
import useURLParams from '../../hooks/useURLParams';
import HelpIconWithHover from '../../components/HelpIconWithHover';
import i18n from '../../i18n';

function ActorsBreadCrumbs() {
  const { getURLParams, routeNavigateTo, currentLocationPathname } = useURLParams();

  const {
    view,
  } = getURLParams();

  const permView = currentLocationPathname === '/admin/actors/permissions';
  const permOsServiceView = currentLocationPathname === '/admin/actors/perms-on-service';

  return (
    <>
      <Breadcrumb>
        <Breadcrumb.Item>
          <a onClick={() => routeNavigateTo('/admin/actors/list')}>
            {capitalizeAndTranslateMsg('auth.pages.actors', 'actors')}
          </a>
        </Breadcrumb.Item>
        {permView && (
        <Breadcrumb.Item>
          {capitalizeAndTranslateMsg('auth.headers.permissions', 'permissions')}
        </Breadcrumb.Item>
        )}
        {permOsServiceView && (
        <Breadcrumb.Item>
          {capitalizeAndTranslateMsg('auth.headers.ds', 'permissions on service')}
          <HelpIconWithHover
            iconSize={1.6}
            iconClassName="ml-1"
            toolTipText={i18n.language === 'en' ? 'This interface displays all actors that have any permissions on the selected service.\n'
                + 'Divided by categories. To view detailed information, you can go to the corresponding interface by clicking on the button opposite each actor.'
              : 'В данном интерфейсе отображены все экторы, которые имеют какие-либо права доступа на выбранном сервисе.\n'
                + 'Разделяются по категориям. Для просмотра детальной информации доступен переход в соответствующий интерфейс по нажатию на кнопку напротив каждого эктора.'}
            toolTipPlacement="bottom"
          />
        </Breadcrumb.Item>
        )}
      </Breadcrumb>
      <Outlet />
    </>
  );
}

export default ActorsBreadCrumbs;
