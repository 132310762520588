// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2023.02.16 || YYYY/MM/DD

import {
  mdiAppleIcloud,
  mdiCloudAlert,
  mdiCloudBraces,
  mdiCloudCheck,
  mdiCloudCheckOutline,
  mdiCloudCircle,
  mdiCloudDownload,
  mdiCloudDownloadOutline,
  mdiCube,
  mdiCubeScan,
  mdiCubeUnfolded,
  mdiDisc,
  mdiFileImport,
  mdiHelpNetworkOutline,
  mdiHelpRhombus,
  mdiHexagon,
  mdiKangaroo,
  mdiKarate,
  mdiKettleAlert,
  mdiLockOutline,
  mdiMovie,
  mdiNotebookCheckOutline,
  mdiNotebookMinus,
  mdiNotebookMultiple,
  mdiQualityHigh,
  mdiQuora,
  mdiRabbit,
  mdiRadar,
  mdiSetNone,
  mdiSilverwareFork,
} from '@mdi/js';

export const PublicInterfaceIconsConstants54Origins = {
  'mdi-apple-icloud': mdiAppleIcloud,
  'mdi-cloud-alert': mdiCloudAlert,
  'mdi-cloud-braces': mdiCloudBraces,
  'mdi-cloud-check': mdiCloudCheck,
  'mdi-cloud-check-outline': mdiCloudCheckOutline,
  'mdi-cloud-circle': mdiCloudCircle,
  'mdi-cloud-download': mdiCloudDownload,
  'mdi-cloud-download-outline': mdiCloudDownloadOutline,
  'mdi-help-network-outline': mdiHelpNetworkOutline,
  'mdi-help-rhombus': mdiHelpRhombus,
  'mdi-hexagon': mdiHexagon,
  'mdi-kettle-alert': mdiKettleAlert,
  'mdi-lock-outline': mdiLockOutline,
  'mdi-karate': mdiKarate,
  'mdi-kangaroo': mdiKangaroo,
  'mdi-movie': mdiMovie,
  'mdi-notebook-check-outline': mdiNotebookCheckOutline,
  'mdi-quality-high': mdiQualityHigh,
  'mdi-quora': mdiQuora,
  'mdi-radar': mdiRadar,
  'mdi-rabbit': mdiRabbit,
  'mdi-notebook-multiple': mdiNotebookMultiple,
  'mdi-notebook-minus': mdiNotebookMinus,
  'mdi-file-import': mdiFileImport,
  'mdi-set-none': mdiSetNone,
  'mdi-silverware-fork': mdiSilverwareFork,
  'mdi-cube': mdiCube,
  'mdi-cube-unfolded': mdiCubeUnfolded,
  'mdi-cube-scan': mdiCubeScan,
  'mdi-disc': mdiDisc,
};

export const COLORS = {
  DARK: '#262626',
  GRAY: '#595959',
  RED: '#f5222d',
  VOLCANO: '#fa541c',
  ORANGE: '#fa8c16',
  GREEN: '#52c41a',
  CYAN: '#13c2c2',
  BLUE: '#1890ff',
  GEEKBLUE: '#2f54eb',
  PURPLE: '#722ed1',
};
