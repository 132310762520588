// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2023.02.17 || YYYY/MM/DD

import React, { useEffect, useMemo, useState } from 'react';
import {
  Col, Dropdown, Row,
} from 'antd';
import Icon from '@mdi/react';
import { mdiCube, mdiMenuDown } from '@mdi/js';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { capitalize } from 'lodash';

import InterfaceIcon54Origins from './InterfaceIcon54Origins';

import { urlAPI } from '../../../api/api';
import { getCookie } from '../../utils54origins';

function InterfacesListDropDown54Origins() {
  const { t } = useTranslation();

  const [interfaces, setInterfaces] = useState([]);

  const items = useMemo(
    () => interfaces.map((item, key) => {
      const {
        service_name: name,
        service_domain: domain,
        icon_color: color,
        service_icon: icon,
      } = item;

      return {
        label: (
          <Link to={domain} target="_blank">
            <Row className="py-2">
              <Col className="d-flex align-items-center">
                <InterfaceIcon54Origins
                  color={color}
                  iconName={icon}
                  size={1.6}
                />
              </Col>
              <Col className="d-flex flex-column justify-content-center pl-3">
                <h3 className="m-0">
                  {name}
                </h3>
                <h5 className="header-secondary m-0">
                  {domain}
                </h5>
              </Col>
            </Row>
          </Link>
        ),
        key: `item-${key}`,
      };
    }),
    [interfaces],
  );

  const requestGetOpenInterfaces = () => {
    axios({
      method: 'get',
      url: `${urlAPI}//get_public_interfaces/`,
      headers: {
        'Content-Type': 'application/json',
        'Session-Token': getCookie('Auth'),
      },
    }).then(({ data }) => {
      setInterfaces(data?.services);
    });
  };

  useEffect(() => {
    requestGetOpenInterfaces();
  }, []);

  return (
    <div id="interfaces_list" className="header-dropdown-wrapper h-100">
      <Dropdown
        menu={{ items }}
        trigger={['click']}
        placement="bottom"
        className="h-100"
      >
        <Row className="cursor-pointer">
          <Col className="d-flex align-items-center">
            <Icon path={mdiCube} size={1.6} />
          </Col>
          <Col className="d-flex align-items-center">
            <h4 className="m-0 ml-3" style={{ userSelect: 'none' }}>
              {capitalize(t('auth.headers.services', 'services'))}
            </h4>
          </Col>
          <Col className="d-flex align-items-center">
            <Icon path={mdiMenuDown} size={1.5} />
          </Col>
        </Row>
      </Dropdown>
    </div>
  );
}

export default InterfacesListDropDown54Origins;
