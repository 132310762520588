import PropTypes from 'prop-types';
import React, {
  useContext, useEffect, useState, useId,
} from 'react';
import { useSelector } from 'react-redux';

import AntTableWithPagination from '../../components/AntTableWithPagination';

import { isActorsFetching } from '../selectors';
import { ApiContext } from '../../api/ApiContextProvider';
import { stopPropagation } from '../../54origins/utils54origins';

function ListOfActorsBasic({
  actorUUID,
  columns,
  filterByTags,
  getActorTypes = [],
  getListOfActorsAgain,
  ignoreSearchCase,
  newCurrentPage,
  orderRules,
  searchValue,
  selectActor,
  switchView,
  typesForActorsRequest,
  doNotMakeRequest,
  getPaginationAndOrderCallback,
  outerActors,
}) {
  const uniqID = useId();

  const {
    requestGetAllActors,
  } = useContext(ApiContext);

  const fetchingActors = useSelector(isActorsFetching);

  const [actorsTableData, setActorsTableData] = useState([]);
  const [actorsCount, changeActorsCount] = useState(0);
  const [paginationOptions, changePaginationOptions] = useState();

  const changePagination = (data) => {
    changePaginationOptions(data);
  };

  const rowSelection = {
    columnWidth: 40,
    selectedRowKeys: [actorUUID],
  };

  const onRow = (row) => ({
    onClick: (e) => {
      stopPropagation(e);

      if (actorUUID === row.uuid) {
        switchView();
      } else if (selectActor) {
        selectActor('info', row.type, row.uuid);
      }
    },
    onDoubleClick: (e) => {
      stopPropagation(e);

      if (selectActor) {
        selectActor('permissions', row.type, row.uuid);
      }
    },
  });

  const changeActorsAndPagination = (data) => {
    const {
      total = 0,
      actors = [],
    } = data || {};

    changeActorsCount(total);
    setActorsTableData(actors);
  };

  const getActorsAndCount = async (params) => {
    const types = typesForActorsRequest || [
      `GET_ALL_ACTORS_REQUEST_${uniqID}`,
      `GET_ALL_ACTORS_SUCCESS_${uniqID}`,
      `GET_ALL_ACTORS_FAILURE_${uniqID}`,
    ];

    const data = {
      actor_type: getActorTypes,
      ...params,
    };

    if (searchValue) {
      data.search_data = {
        value: searchValue,
        ignore_case: ignoreSearchCase,
        fields:
            {
              base: ['uuid'],
              uinfo: ['first_name', 'last_name', 'group_name', 'service_name'],
            },
      };
    }
    const allActors = await requestGetAllActors(data, types);

    changeActorsAndPagination(allActors);
  };

  const initFunc = () => {
    if (paginationOptions) {
      const { pageLimit, offset } = paginationOptions || {};

      const params = {
        limit: pageLimit,
        offset,
        ...orderRules,
      };

      if (!doNotMakeRequest) {
        getActorsAndCount(params);
      }
    }
  };

  useEffect(() => {
    initFunc();

    getPaginationAndOrderCallback?.({
      ...orderRules,
      ...paginationOptions,
    });
  }, [
    paginationOptions,
    JSON.stringify(orderRules),
    JSON.stringify(filterByTags),
    JSON.stringify(getListOfActorsAgain),
  ]);

  useEffect(() => {
    changeActorsAndPagination(outerActors);
  }, [JSON.stringify(outerActors)]);

  return (
    <AntTableWithPagination
      columns={columns}
      data={actorsTableData}
      loading={fetchingActors}
      onRow={onRow}
      rowSelection={rowSelection}
      total={actorsCount}
      newCurrentPage={newCurrentPage}
      getPaginationOptions={changePagination}
    />
  );
}

export default ListOfActorsBasic;

ListOfActorsBasic.propTypes = {
  actorUUID: PropTypes.string,
  columns: PropTypes.any,
  filterByTags: PropTypes.any,
  getActorTypes: PropTypes.array,
  getListOfActorsAgain: PropTypes.any,
  ignoreSearchCase: PropTypes.bool,
  newCurrentPage: PropTypes.any,
  orderRules: PropTypes.any,
  searchValue: PropTypes.any,
  selectActor: PropTypes.any,
  switchView: PropTypes.any,
  typesForActorsRequest: PropTypes.array,
  getPaginationAndOrderCallback: PropTypes.func,
  doNotMakeRequest: PropTypes.bool,
  outerActors: PropTypes.object,
};
