import PropTypes from 'prop-types';
import React, { memo, useState } from 'react';
import { Modal } from 'antd';
import AntButtonWithMDI from './AntButtonWithMDI';

const AntButtonWithModal = memo(({
  btnClassName,
  btnDisabled,
  btnType,
  btnPathForMdi,
  btnSizeMdi,
  btnLabel,
  btnIconClassName,
  btnSize,
  children,
  modalTitle,
  modalWidth = '70%',
  modalFooter = [],
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const btnOnClick = () => {
    setModalVisible(true);
  };

  const onCancel = () => {
    setModalVisible(false);
  };

  return (
    <>
      <AntButtonWithMDI
        className={btnClassName}
        disabled={btnDisabled}
        type={btnType}
        pathForMdi={btnPathForMdi}
        sizeMdi={btnSizeMdi}
        label={btnLabel}
        onClick={btnOnClick}
        iconClassName={btnIconClassName}
        size={btnSize}
      />
      <Modal
        title={modalTitle}
        open={modalVisible}
        width={modalWidth}
        destroyOnClose
        onCancel={onCancel}
        footer={modalFooter}
      >
        {children}
      </Modal>
    </>
  );
});

export default AntButtonWithModal;

AntButtonWithModal.propTypes = {
  btnClassName: PropTypes.string,
  btnDisabled: PropTypes.bool,
  btnType: PropTypes.string,
  btnPathForMdi: PropTypes.element,
  btnSizeMdi: PropTypes.string,
  btnLabel: PropTypes.string,
  btnIconClassName: PropTypes.string,
  btnSize: PropTypes.string,
  children: PropTypes.element,
  modalTitle: PropTypes.string,
  modalWidth: PropTypes.string,
  modalFooter: PropTypes.any,
};
