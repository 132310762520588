import { useReducer, useState } from 'react';

export default function useCustomState(defaultState = {}) {
  const [state, setState] = useState(defaultState);

  const [toggle, setToggle] = useReducer((state) => !state, false);

  const changeState = (params) => {
    // console.log('params', params);
    setState((prev) => ({ ...prev, ...params }));
  };

  return {
    ...state,
    toggle,
    setToggle,
    changeState,
  };
}
