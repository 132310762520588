import PropTypes from 'prop-types';
import React, { useState } from 'react';
import {
  mdiAccountDetailsOutline,
} from '@mdi/js';
import { useTranslation } from 'react-i18next';
import {
  Alert, Col, DatePicker, Form, Input, Row, Switch,
} from 'antd';
import { isEmpty } from 'lodash';
import moment from 'moment';

import InfoBlock from '../../../components/InfoBlock';
import ChangeTypeOfUserBtn from '../ChangeTypeOfUserBtn';
import AddActorsForNewActor from '../AddActorsForNewActor';
import InternalOrExternalSwitcher from '../InternalOrExternalSwitcher';
import GeneratePassBtn from '../GeneratePassBtn';
import GetActorsAndShowListOfActors from '../GetActorsAndShowListOfActors';

import { capitalizeAndTranslateMsg } from '../../../mainUtils';

function IsRobotActorSwitch({
  switchValueCallback,
}) {
  const { t } = useTranslation();

  const [switchValue, setSwitchValue] = useState(false);

  const changeSwitch = () => {
    setSwitchValue(!switchValue);

    if (switchValueCallback) {
      switchValueCallback(!switchValue);
    }
  };

  return (
    <Form.Item
      label={capitalizeAndTranslateMsg('auth.headers.user type', 'Actor is robot')}
      name="isRobot"
    >
      <Switch
        className="switch-primary"
        checkedChildren={capitalizeAndTranslateMsg('auth.buttons.yes', 'yes')}
        unCheckedChildren={capitalizeAndTranslateMsg('auth.buttons.no', 'no')}
        checked={switchValue}
        onClick={changeSwitch}
      />
    </Form.Item>
  );
}

function UserInfoFormItems(props) {
  const {
    actorData,
    actorForm,
    actorType,
    actorUUID,
    creating,
    editMode,
    generatePassCallBack,
    hideElements = [],
    isProfile,
    rules,
  } = props;

  const { t } = useTranslation();

  const { setFieldsValue, getFieldsValue } = actorForm;
  const {
    typeOfUser = actorType,
    login,
    phone_number,
    email,
    newKeyPair = false,
  } = getFieldsValue(['typeOfUser', 'newKeyPair', 'phone_number', 'login', 'email']);

  const checkOneOfRequiredInput = () => {
    if (isEmpty(login) && isEmpty(phone_number) && isEmpty(email)) {
      return true;
    }
    return !(isEmpty(login) || isEmpty(phone_number) || isEmpty(email));
  };

  const customRules = {
    email: [{ type: 'email' }, {
      required: checkOneOfRequiredInput(),
      message: 'Please input your email ',
    }],
    login: [
      {
        pattern: /^[a-zA-Z0-9$@$!%*?&#^-_. +]+$/,
        message: 'Login length must be from 3 to 36 and it must contain alphanumeric values, dots or underscores. Only latin letters',
      },
      { min: 3 }, { max: 36 },
      { required: checkOneOfRequiredInput() },
    ],
    phone_number: [
      {
        pattern: /[\\+][0-9]*\d/,
        message: 'Phone number is not valid. Number must start with +',
      },
      { required: checkOneOfRequiredInput() },
    ],
  };

  const switchValueCallback = (value) => {
    setFieldsValue({ newKeyPair: value });
  };

  const changeTypeOfUserCallback = (value) => {
    setFieldsValue({
      typeOfUser: value,
    });
  };

  const getSelectedActorsForNewActors = ({ addActorsList, removeActorsList }) => {
    // console.log('getSelectedActorsForNewActors');
    setFieldsValue({
      addActorsList,
      removeActorsList,
    });
  };

  const disabledDate = (current) => current > moment() || current < moment().subtract(80, 'year');

  const userIsClassic = actorType === 'classic_user' || typeOfUser === 'classic_user';

  const checkHidden = (paramName) => hideElements.includes(paramName);

  return (
    <div>
      <InfoBlock
        iconPath={mdiAccountDetailsOutline}
        title={capitalizeAndTranslateMsg('auth.headers.user_detail', 'user detail')}
      >
        <>
          <Row gutter={[16, 0]}>
            <Col span={12}>
              <Form.Item
                label={capitalizeAndTranslateMsg('auth.headers.first_name', 'first name')}
                name="first_name"
                rules={rules.first_name}
              >
                <Input
                  placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.first_name') })}
                  disabled={!editMode}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={capitalizeAndTranslateMsg('auth.headers.last_name', 'last name')}
                name="last_name"
                rules={rules.last_name}
              >
                <Input
                  placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.last_name_enter') })}
                  disabled={!editMode}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={[16, 0]}>
            {userIsClassic && (
            <Col span={12}>
              <Form.Item
                label={capitalizeAndTranslateMsg('auth.headers.login', 'login')}
                name="login"
                rules={customRules.login}
              >
                <Input
                  autoComplete="new-password"
                  placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.login') })}
                  disabled={!editMode}
                />
              </Form.Item>
            </Col>
            )}
            <Col span={12}>
              <Form.Item
                label={capitalizeAndTranslateMsg('auth.headers.email', 'email')}
                name="email"
                rules={customRules.email}
              >
                <Input
                  placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.email') })}
                  disabled={!editMode}
                />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label={capitalizeAndTranslateMsg('auth.headers.email_service', 'Internal email')}
                name="internalemail"
                  // rules={customRules.email}
              >
                <Input
                  placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.email') })}
                  disabled={!editMode}
                />
              </Form.Item>
            </Col>
            {userIsClassic && !checkHidden('phone')
            && (
            <Col span={12}>
              <Form.Item
                label={capitalizeAndTranslateMsg('auth.headers.phone', 'phone number')}
                name="phone_number"
                rules={customRules.phone_number}
              >
                <Input
                  placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.phone') })}
                  disabled={!editMode}
                />
              </Form.Item>
            </Col>
            )}
            <Col span={12}>
              <Form.Item
                label={capitalizeAndTranslateMsg('auth.headers.birthday', 'birthday')}
                name="birthday"
              >
                <DatePicker
                  format="YYYY-MM-DD"
                  className="w-100"
                  placeholder={capitalizeAndTranslateMsg('auth.placeholders.select', { value: t('auth.headers.birthday') })}
                  disabled={!editMode}
                  disabledDate={disabledDate}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={[16, 0]}>
            {userIsClassic && (
            <>
              {!checkHidden('oldPassword')
                  && (
                  <Col span={12}>
                    <Form.Item
                      label={capitalizeAndTranslateMsg('auth.headers.old_password', 'old password')}
                      name="old_password"
                      rules={rules.old_password}
                    >
                      <Input.Password
                        autoComplete="new-password"
                        placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.password') })}
                        disabled={!editMode}
                      />
                    </Form.Item>
                  </Col>
                  )}

              <Col span={12}>
                <Form.Item
                  label={creating
                    ? capitalizeAndTranslateMsg('auth.headers.password', 'password')
                    : capitalizeAndTranslateMsg('auth.headers.new_password', 'new password')}
                  name="password"
                  rules={rules.password}
                >
                  <Input.Password
                    placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.password') })}
                    disabled={!editMode}
                    autoComplete="newPassword"
                  />
                </Form.Item>
              </Col>
              {!checkHidden('passwordConfirmation')
                  && (
                  <Col span={12}>
                    <Form.Item
                      label={capitalizeAndTranslateMsg('auth.headers.old_password', 'password confirmation')}
                      name="password_confirmation"
                      rules={rules.password_confirmation}
                      dependencies={['password']}
                    >
                      <Input.Password
                        placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.password') })}
                        disabled={!editMode}
                      />
                    </Form.Item>
                    {creating && <GeneratePassBtn onClickCallBack={generatePassCallBack} />}
                  </Col>
                  )}
              {/* <Col className="ml-auto"> */}
              {/* */}
              {/* </Col> */}
            </>
            )}
          </Row>
          {!creating && editMode && !checkHidden('userTypeAndKeys') && (
            <Row gutter={[16, 0]}>
              <Col span={12}>
                <ChangeTypeOfUserBtn
                  defaultUserType={actorType}
                  changeTypeOfUserCallback={changeTypeOfUserCallback}
                />
              </Col>
              <Col span={12}>
                {/* {!userIsClassic && ( */}
                {/*  <GenerateKeyPairForActorSwitch */}
                {/*    switchValueCallback={switchValueCallback} */}
                {/*  /> */}
                {/* )} */}
              </Col>
            </Row>
          )}

          {!userIsClassic && (editMode || creating) && !checkHidden('userTypeAndKeys') && (
          <Row>
            <Col>
              <Alert
                message="Backup info"
                description={`
                    After generating new keys or changing the user type, you will be prompted to download the user backup.                  
                    Default password: 1111                   
                  `}
                type="info"
                showIcon
              />
            </Col>
          </Row>
          )}
        </>
        <Row gutter={[16, 0]}>
          {!creating && !isProfile && !userIsClassic && (
          <Col span={24}>
            <Form.Item
              label={capitalizeAndTranslateMsg('auth.headers.initial_key', 'initial key')}
              name="initial_key"
              rules={rules.initial_key}
            >
              <Input.TextArea
                autoSize={{
                  minRows: 3,
                  maxRows: 5,
                }}
                placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.initial_key') })}
                disabled={!editMode || newKeyPair}
              />
            </Form.Item>
          </Col>
          )}
        </Row>
      </InfoBlock>
      <InfoBlock
        iconPath={mdiAccountDetailsOutline}
        title={capitalizeAndTranslateMsg('auth.headers.user_groups', 'user groups')}
      >
        <Row>
          <Col span={12}>
            {creating && (
            <AddActorsForNewActor
              actorType="user"
              onSaveCallBack={getSelectedActorsForNewActors}
              btnModalLabel="add groups"
            />
            )}
            {actorUUID && (<GetActorsAndShowListOfActors actorData={actorData} />)}
          </Col>
          <Col span={6}>
            {creating && !checkHidden('internal') && <InternalOrExternalSwitcher />}
          </Col>
          {creating && (
          <Col span={6}>
            <IsRobotActorSwitch />
          </Col>
          )}
        </Row>
      </InfoBlock>


      {/* {!isProfile && ( */}
      {/* <InfoBlock */}
      {/*  iconPath={mdiNoteEditOutline} */}
      {/*  title={capitalizeAndTranslateMsg('auth.headers.comment', 'comment')} */}
      {/* > */}
      {/*  <Row> */}
      {/*    <Col span={24}> */}
      {/*      <Form.Item */}
      {/*        name="comment" */}
      {/*        rules={rules.comment} */}
      {/*      > */}
      {/*        <Input.TextArea */}
      {/*          placeholder={capitalizeAndTranslateMsg('auth.placeholders.enter', { value: t('auth.headers.comment') })} */}
      {/*          autoSize={{ */}
      {/*            minRows: 3, */}
      {/*            maxRows: 5, */}
      {/*          }} */}
      {/*          disabled={!editMode} */}
      {/*        /> */}
      {/*      </Form.Item> */}
      {/*    </Col> */}
      {/*  </Row> */}
      {/* </InfoBlock> */}
      {/* )} */}
    </div>
  );
}

export default UserInfoFormItems;

UserInfoFormItems.propTypes = {
  actorData: PropTypes.any,
  actorForm: PropTypes.object.isRequired,
  actorType: PropTypes.string.isRequired,
  actorUUID: PropTypes.string,
  creating: PropTypes.bool,
  editMode: PropTypes.bool,
  generatePassCallBack: PropTypes.func,
  hideElements: PropTypes.array,
  isProfile: PropTypes.bool,
  rules: PropTypes.object,
};
