/* eslint-disable */
// noinspection ES6CheckImport
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './modules';


// const initialState = {};
// const enhancers = [];
// const middleware = [
//     thunkMiddleware,
//   // routerMiddleware(history)
// ];

// noinspection JSUnresolvedVariable
// if (process.env.NODE_ENV === 'development') {
//   // noinspection JSUnresolvedVariable
//   const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;
//
//   if (typeof devToolsExtension === 'function') {
//     enhancers.push(devToolsExtension());
//   }
// }

// const composedEnhancers = compose(applyMiddleware(...middleware), ...enhancers);

// export default createStore(
//   rootReducer(),
//   initialState,
//   composedEnhancers
// );





const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware(
        {
            serializableCheck: false,
        },
    ),
});
export default function configure() {
    return { store };
}
