import { ActorsConstants } from '../constants/actionTypes';

const initialState = {
  fetching: false,
  actorsData: [],
  type: {},
};

export default (state = initialState, action = {}) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case ActorsConstants.CREATE_ACTOR_REQUEST:
      return {
        ...state,
        fetching: true,
        actorsData: [],
        error: '',
      };

    case ActorsConstants.CREATE_ACTOR_SUCCESS:
      return {
        ...state,
        fetching: false,
        actorsData: payload.actor,
        error: '',
      };

    case ActorsConstants.CREATE_ACTOR_FAILURE:
      return {
        ...state,
        fetching: false,
        actorsData: [],
        type: {},
      };

    case ActorsConstants.CREATE_ACTOR_CANCELED:
      return {
        ...state,
        fetching: false,
      };
    default:
      return state;
  }
};
