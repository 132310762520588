import { ActorsConstants } from '../constants/actionTypes';

const initialState = {
  fetching: false,
  data: [],
  error: '',
};

export default (state = initialState, action = {}) => {
  const { type, payload, error } = action;

  switch (type) {
    case ActorsConstants.CHECK_SERVICE_VERSION_REQUEST:
      return {
        ...state,
        fetching: true,
        data: [],
        error: '',
      };

    case ActorsConstants.CHECK_SERVICE_VERSION_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: payload,
        error: error || '',
      };

    case ActorsConstants.CHECK_SERVICE_VERSION_FAILURE:
      return {
        ...state,
        fetching: false,
        error: '',
      };

    default: return state;
  }
};
