import PropTypes from 'prop-types';
import React, {
  useEffect,
  useReducer,
  useState,
} from 'react';
import { Badge, Button, Modal } from 'antd';
import Icon from '@mdi/react';
import { mdiPlusCircleOutline } from '@mdi/js';

import ModalListOfActors from './ModalListOfActors';
import ListOfActorsTags from './ListOfActorsTags';

import {
  antNotification,
  capitalizeAndTranslateMsg,
  stopPropagation,
} from '../../mainUtils';
import useAuthActorsRequests from '../hooks/useAuthActorsRequests';

function ActorsModalBtn({
  actorType,
  actorUUID,
  btnModalLabel,
  initialActorsUUIDs = [],
  outerActorsUUIDs,
  doNotMakeRequest = [],
  hideElements = [],
  iconPath = mdiPlusCircleOutline,
  listOfActorsActionBtnLabel,
  modalTitle,
  onCancelModalCallback,
  onOkModalCallback,
  onSaveCallBack,
  openModalFromOuter,
  resetFromOuterComponent,
}) {

  const {
    requestUpdateActorOnAuth,
    requestGetListOfActorsOnAuth,
    requestGetActorOnAuth,
  } = useAuthActorsRequests();

  const defaultState = {
    selectedActors: [],
    addedActors: [],
    addedActorsUUIDs: [],
    removedActors: [],
    removedActorsUUIDs: [],
  };

  const [modalVisible, changeModalVisible] = useReducer((state) => !state, false);
  const [state, setState] = useState(defaultState);

  const {
    selectedActors,
    addedActors,
    addedActorsUUIDs,
    removedActors,
    removedActorsUUIDs,
  } = state || {};

  const localActorsIsChanged = addedActorsUUIDs?.length !== 0 || removedActorsUUIDs?.length !== 0;

  const changeState = (params) => {
    setState((prev) => ({ ...prev, ...params }));
  };

  const getSelectedActorsFromModal = (actors) => {
    const actorsUUIDs = actors.map((item) => item.uuid);

    changeState({
      selectedActors: [...selectedActors, ...actors],
      addedActorsUUIDs: [...addedActorsUUIDs, ...actorsUUIDs],
      addedActors: [...addedActors, ...actors],
    });
  };

  const getDataForListOfActorsRequest = () => {
    switch (actorType) {
      case 'user':
      case 'classic_user':
      case 'editClassic_user':
      case 'editUser':
      case 'service':
      case 'newService':
      case 'editService':
        return {
          actor_type: ['group'],
          uuid__not: selectedActors.map(({ uuid }) => uuid),
        };
      case 'group':
      case 'newGroup':
      case 'editGroup':
        return {
          actor_type: ['user', 'classic_user'],
          uuid__not: selectedActors.map(({ uuid }) => uuid),
        };
      default:
        break;
    }
  };

  const onOkModal = () => {
    changeModalVisible();

    onOkModalCallback?.();
  };

  const removeSelectedActors = (removedActor) => {
    const removedActorUUID = removedActor?.uuid;

    changeState({
      selectedActors: selectedActors.filter(({ uuid }) => uuid !== removedActorUUID),
      removedActorsUUIDs: [...removedActorsUUIDs, removedActorUUID],
      removedActors: [...removedActors, removedActor],
    });
  };

  const getSelectedActorsRequest = () => {
    const data = {};

    switch (actorType) {
      case 'user':
      case 'classic_user':
      case 'editClassic_user':
      case 'editUser':
      case 'service':
        data.actor_type = ['group'];

        if (initialActorsUUIDs.length !== 0) {
          data.uuid = initialActorsUUIDs;

          return requestGetListOfActorsOnAuth(data, []);
        }

        const actorConstants = [
          'GET_ACTOR_FOR_ACTORS_MODAL_FETCHING',
          'GET_ACTOR_FOR_ACTORS_MODAL_SUCCESS',
          'GET_ACTOR_FOR_ACTORS_MODAL_FAILURE',
        ];

        if (actorUUID) {
          return requestGetActorOnAuth({
            uuid: actorUUID,
            constants: actorConstants,
          }).then((res) => {
            const { groups } = res || {};

            if (groups.length !== 0) {
              data.uuid = groups.map(({ uuid }) => uuid);

              return requestGetListOfActorsOnAuth(data, []);
            }
          });
        }

        break;
      case 'group':
        data.uinfo = {};
        data.uinfo.groups = [actorUUID];
        return requestGetListOfActorsOnAuth(data, []);
      case 'newService':
      case 'editService':
        if (initialActorsUUIDs.length !== 0) {
          data.actor_type = ['group'];
          data.uuid = initialActorsUUIDs;
          return requestGetListOfActorsOnAuth(data, []);
        }

        return [];
      case 'newGroup':
        if (initialActorsUUIDs.length !== 0) {
          data.actor_type = ['user', 'classic_user'];
          data.uuid = initialActorsUUIDs;
          return requestGetListOfActorsOnAuth(data, []);
        }

        return [];
      case 'editGroup':
        if (outerActorsUUIDs) {
          data.uuid = outerActorsUUIDs;

          return requestGetListOfActorsOnAuth(data, []);
        } else {
          data.uinfo = {};
          data.uinfo.groups = [actorUUID];

          return requestGetListOfActorsOnAuth(data, []);
        }

      default:
        return [];
    }
  };

  const onCancel = () => {
    changeModalVisible();

    changeState(defaultState);

    if (onCancelModalCallback) {
      onCancelModalCallback();
    }
  };

  const onSave = () => {
    const changedActors = {
      selectedActors,
      addActorsList: addedActorsUUIDs,
      removeActorsList: removedActorsUUIDs,
      addedActors,
      removedActors,
    };

    if (!doNotMakeRequest.includes('saveActors') && localActorsIsChanged) {
      requestUpdateActorOnAuth({
        actor_type: actorType,
        add_actors_list: addedActorsUUIDs,
        remove_actors_list: removedActorsUUIDs,
        uuid: actorUUID,
      }).then(() => {
        antNotification.defaultSuccess();

        onSaveCallBack?.(changedActors);
      });
    } else {
      onSaveCallBack?.(changedActors);
    }

    onCancel();
  };

  const getBtnModalLabel = () => {
    switch (actorType) {
      case 'user':
      case 'classic_user':
      case 'service':
      case 'newService':
        return capitalizeAndTranslateMsg('54origins.noun.group.1', 'groups');
      case 'group':
      case 'newGroup':
        return capitalizeAndTranslateMsg('54origins.noun.user.1', 'users');
      default:
        return 'actor';
    }
  };

  const getListOfActorsActionBtnLabel = () => {
    switch (actorType) {
      case 'user':
      case 'classic_user':
      case 'service':
      case 'newService':
        return 'add groups';
      case 'group':
      case 'newGroup':
        return 'add user';
      default:
        return 'add actor';
    }
  };

  const getModalTitle = () => {
    switch (actorType) {
      case 'user':
      case 'classic_user':
        return 'User groups';
      case 'service':
      case 'newService':
        return 'Service groups';
      case 'group':
      case 'newGroup':
        return 'Users';
      default:
        return 'add actor';
    }
  };

  const showModal = async (e) => {
    stopPropagation(e);

    if (!doNotMakeRequest.includes('getModalSelectedActors')) {
      const actorsData = await getSelectedActorsRequest();

      const {
        actors,
      } = actorsData || {};

      changeState({ selectedActors: actors ?? [] });
    }

    changeModalVisible();
  };

  useEffect(() => {
    if (openModalFromOuter) {
      showModal();
    }
  }, [openModalFromOuter]);

  useEffect(() => {
    if (resetFromOuterComponent) {
      changeState(defaultState);
    }
  }, [resetFromOuterComponent]);

  return (
    <div
      onClick={stopPropagation}
      onDoubleClick={stopPropagation}
    >
      <Modal
        title={modalTitle || getModalTitle()}
        open={modalVisible}
        onOk={onOkModal}
        onCancel={onCancel}
        destroyOnClose
        width="40%"
        centered
        footer={[
          <Button
            key="back"
            onClick={onCancel}
          >
            Cancel
          </Button>,
          <Button
            key="submit"
            type="primary"
            onClick={onSave}
            disabled={!localActorsIsChanged}
          >
            Save
          </Button>,
        ]}
      >
        <>
          <ListOfActorsTags
            actors={selectedActors}
            getRemovedUserCallback={removeSelectedActors}
            isModal
          />
          <ModalListOfActors
            getSelectedActors={getSelectedActorsFromModal}
            listOfActorsActionBtnLabel={listOfActorsActionBtnLabel || getListOfActorsActionBtnLabel()}
            dataForActorsRequest={getDataForListOfActorsRequest()}
          />
        </>
      </Modal>
      {!hideElements.includes('mainBtn') && (
        <Button
          type="default"
          onClick={showModal}
          size="small"
          className="button-primary-outlined actorsModalBtn"
        >
          {!hideElements.includes('btnIcon') && (
            <Icon
              size={1}
              className="mr-1"
              path={iconPath}
            />
          )}
          <span>{btnModalLabel || getBtnModalLabel()}</span>
        </Button>
      )}
    </div>
  );
}

export default ActorsModalBtn;

ActorsModalBtn.propTypes = {
  actorType: PropTypes.string,
  actorUUID: PropTypes.string,
  btnModalLabel: PropTypes.string,
  initialActorsUUIDs: PropTypes.array,
  doNotMakeRequest: PropTypes.array,
  hideElements: PropTypes.array,
  iconPath: PropTypes.any,
  listOfActorsActionBtnLabel: PropTypes.string,
  modalTitle: PropTypes.string,
  onCancelModalCallback: PropTypes.func,
  onOkModalCallback: PropTypes.func,
  onSaveCallBack: PropTypes.func,
  openModalFromOuter: PropTypes.any,
};
