// This hook also used on another biome service
// All query names must be in this format "request[request name]OnAuth".

import { useDispatch } from 'react-redux';
import axiosRequest from '../../api/api';
import { ActorsConstants } from '../constants/actionTypes';

const useAuthActorsRequests = () => {
  const dispatch = useDispatch();

  const optionsForAuthRequest = (resolve, reject) => ({
    onFailure: reject,
    onSuccess: resolve,
  });

  const requestGetListOfActorsOnAuth = (data, types) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'get/actors',
        [...types],
        data,
        optionsForAuthRequest(resolve, reject),
      ),
    );
  });

  const requestUpdateActorOnAuth = (data) => new Promise((resolve, reject) => {
    dispatch(
      axiosRequest.post(
        'update/actor',
        [
          ActorsConstants.UPDATE_ACTOR_REQUEST,
          ActorsConstants.UPDATE_ACTOR_SUCCESS,
          ActorsConstants.UPDATE_ACTOR_FAILURE,
          ActorsConstants.UPDATE_ACTOR_CANCEL,
        ],
        data,
        optionsForAuthRequest(resolve, reject),
      ),
    );
  });

  const requestGetActorOnAuth = ({
    uuid,
    constants,
  }) => {
    const finalConstants = constants || [
      ActorsConstants.GET_ACTORS_DATA_REQUEST,
      ActorsConstants.GET_ACTORS_DATA_SUCCESS,
      ActorsConstants.GET_ACTORS_DATA_FAILURE,
    ];

    return new Promise((resolve, reject) => {
      dispatch(
        axiosRequest.post(
          `get/actor/${uuid}`,
          finalConstants,
          {},
          optionsForAuthRequest(resolve, reject),
        ),
      );
    });
  };

  return ({
    requestGetListOfActorsOnAuth,
    requestUpdateActorOnAuth,
    requestGetActorOnAuth,
  });
};

export default useAuthActorsRequests;
