import PropTypes from 'prop-types';
import React from 'react';
import { Col, Row } from 'antd';

function PageWrapper({
  title,
  children,
}) {
  return (
    <>
      {title && (
      <Row>
        <Col span={24}>
          <h1 className="header-page">
            {title}
          </h1>
        </Col>
      </Row>
      )}
      {children}
    </>
  );
}

export default PageWrapper;

PageWrapper.propTypes = {
  children: PropTypes.element.isRequired,
  title: PropTypes.string,
};
