import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Tag } from 'antd';
import Icon from '@mdi/react';
import { mdiCloseBox } from '@mdi/js';

import { getNameForActor } from '../../54origins/utils54origins';

const styleForContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
};

function ListOfActorsTags({
  actors = [],
  getRemovedUserCallback,
  isModal,
}) {
  const [localActors, setLocalActors] = useState([]);

  const getTagsTemplate = (item) => {
    const removeActor = () => getRemovedUserCallback?.(item);

    if (isModal) {
      return (
        <span
          className="mr-3 mt-2"
          style={{
            position: 'relative',
          }}
        >
          <Tag
            onClick={removeActor}
            color="blue"
            style={{
              fontSize: 15,
            }}
          >
            {getNameForActor(item)}
          </Tag>

          <Icon
            uuid={item.uuid}
            onClick={removeActor}
            style={{
              position: 'absolute',
              top: '-10px',
              right: '-10px',
              zIndex: '10',
              cursor: 'pointer',
            }}
            path={mdiCloseBox}
            size={1.1}
            color="red"
          />
        </span>
      );
    }
    return (
      <Tag
        color="blue"
        className="mr-2 mt-2"
      >
        {getNameForActor(item)}
      </Tag>
    );
  };

  useEffect(() => {
    setLocalActors(actors);
  }, [JSON.stringify(actors)]);

  return (
    <div>
      <div style={styleForContainer}>
        {localActors?.map((item) => (
          getTagsTemplate(item)
        ))}
      </div>
    </div>
  );
}

export default ListOfActorsTags;

ListOfActorsTags.propTypes = {
  actors: PropTypes.array,
  getRemovedUserCallback: PropTypes.func,
  isModal: PropTypes.bool,
};
