import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import IStickyBox from 'react-sticky-box';
import {
  Button, Card, Col, Form, Row, Skeleton, Space, Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import Icon from '@mdi/react';
import {
  mdiClose, mdiContentCopy, mdiContentSaveOutline, mdiSquareEditOutline, mdiTrashCanOutline,
} from '@mdi/js';
import _, { isEqual, uniqBy } from 'lodash';
import { useSelector } from 'react-redux';

import DeleteButton from '../../components/DeleteButton';
import InterfaceForm from './InterfaceForm';
import InterfaceIcon54Origins from '../../54origins/components/publicInterface54Origins/InterfaceIcon54Origins';

import { antNotification, capitalize, textToClipboard } from '../../mainUtils';
import { getInterfaceData, getInterfaceFetching } from '../selectors';
import { ApiContext } from '../../api/ApiContextProvider';
import useCustomState from '../../hooks/useCustomState';

function InterfaceInfo({
  onSuccessDelete,
  interfaceID,
  onSuccessUpdate,
}) {
  const { t } = useTranslation();
  const [interfaceForm] = Form.useForm();

  const {
    requestGetInterface,
    requestUpdateInterface,
    requestGetGroupsForPublicInterface,
    requestSetPublicInterfaceForActor,
  } = useContext(ApiContext);

  const defaultInterfaceData = useSelector(getInterfaceData);
  const interfaceFetching = useSelector(getInterfaceFetching);

  const initInterfaceData = {
    interfaceIcon: 'mdi-cube',
    interfaceColor: 'DARK',
  };

  const {
    editMode,
    interfaceIcon,
    interfaceColor,
    localGroupsForPI,
    changeState,
  } = useCustomState({
    editMode: false,
    ...initInterfaceData,
  });

  const onSetEditMode = (editState) => {
    changeState({ editMode: editState });
  };

  const setInterfaceFormData = () => {
    interfaceForm.setFieldsValue({
      ...defaultInterfaceData,
      params: JSON.stringify(defaultInterfaceData.params, null, 4),
    });

    changeState({
      interfaceIcon: _.get(defaultInterfaceData, 'service_icon', initInterfaceData.icon),
      interfaceColor: _.get(defaultInterfaceData, 'icon_color', initInterfaceData.color),
    });
  };

  const onCancel = () => {
    changeState({ editMode: false });
    interfaceForm.resetFields();
    setInterfaceFormData();
  };

  const getGroupsForPublicInterface = () => (
    requestGetGroupsForPublicInterface(interfaceID)
      .then((res) => {
        changeState({ localGroupsForPI: res?.groups || [] });
      })
  );

  const initFunc = () => {
    if (interfaceID) {
      interfaceForm.resetFields();
      changeState(initInterfaceData);
      requestGetInterface(interfaceID).then();

      getGroupsForPublicInterface();
    }
  };

  const onSubmitForm = async () => {
    try {
      const formData = await interfaceForm.validateFields();

      const data = {
        ...defaultInterfaceData,
        ...formData,
        service_icon: interfaceIcon,
        icon_color: interfaceColor,
        params: JSON.parse(formData.params),
      };

      requestUpdateInterface(data).then(() => {
        antNotification.success(
          capitalize(t('auth.notifications.updated_successfully', 'updated successfully')),
        );

        changeState({ editMode: false });

        onSuccessUpdate?.();
      });
    } catch (error) {
      antNotification.error(capitalize(t('auth.notifications.invalid_data', 'invalid data entered')));
    }
  };

  const setPublicInterfaceForActor = (actorUUID, publicInterfacesIDs) => (
    requestSetPublicInterfaceForActor({
      actorUUID,
      publicInterfacesIDs,
    })
  );

  const onSaveCallBackAndSetLocalActors = async (actors) => {
    const { addedActors, removedActors, selectedActors } = actors || {};

    const getArraysOfUUID = (arr) => arr.map((item) => item?.uuid);
    const publicInterfaceGroupsIsEqual = isEqual(getArraysOfUUID(localGroupsForPI), getArraysOfUUID(selectedActors));

    const arrOfPromises = [];

    // console.log('ID', interfaceID)

    if (!publicInterfaceGroupsIsEqual) {
      for (const selectedGroup of selectedActors) {
        const {
          uuid,
          uinfo: {
            public_interfaces: publicInterfaces = [],
          } = {},
        } = selectedGroup || {};

        // console.log('selectedGroup', selectedGroup)

        const arrOfPublicInterfaceID = [...publicInterfaces.map((item) => item?.id)];
        let newPIForGroup = [...arrOfPublicInterfaceID];

        if (addedActors.length !== 0 && !arrOfPublicInterfaceID.includes(interfaceID)) {

          // console.log('111')

          newPIForGroup = uniqBy([...newPIForGroup, interfaceID]);

          arrOfPromises.push(setPublicInterfaceForActor(uuid, newPIForGroup));
          // await setPublicInterfaceForActor(uuid, newPIForGroup);


        }
      }

      for (const removedGroup of removedActors) {
        const {
          uuid,
          uinfo: {
            public_interfaces: publicInterfaces = [],
          } = {},
        } = removedGroup || {};
        // console.log('removedGroup', removedGroup)
        const arrOfPublicInterfaceID = [...publicInterfaces.map((item) => item?.id)];

        if (arrOfPublicInterfaceID.includes(interfaceID)) {
          // console.log('222')
          arrOfPromises.push(setPublicInterfaceForActor(uuid, arrOfPublicInterfaceID.filter((item) => item !== interfaceID)));
          // await setPublicInterfaceForActor(uuid, arrOfPublicInterfaceID.filter((item) => item !== interfaceID));
        }
      }

      onSuccessUpdate?.();

      // await getGroupsForPublicInterface();
      arrOfPromises.push(getGroupsForPublicInterface());

      await Promise.all(arrOfPromises);
    }
  };

  useEffect(() => {
    if (defaultInterfaceData.id) {
      setInterfaceFormData();
    }
  }, [defaultInterfaceData]);

  useEffect(() => {
    changeState({ editMode: false });
    initFunc();
  }, [interfaceID]);

  return (
    <IStickyBox offsetTop={16} offsetBottom={16}>
      <Spin
        spinning={interfaceFetching || !defaultInterfaceData.id}
      >
        <Card className="card border-blue">
          <Row gutter={[16, 0]} wrap={false}>
            <Col flex="none">
              <h4 className="header-primary">
                {capitalize(t('auth.headers.interface_info', 'interface info'))}
              </h4>
            </Col>
            <Col flex="auto" className="d-flex justify-content-end">
              <Space size={[5, 1]} wrap className="justify-content-end">
                {editMode ? (
                  <>
                    <Button
                      className="button-secondary-outlined"
                      size="small"
                      onClick={onCancel}
                    >
                      <Icon path={mdiClose} size={1} className="mr-1" />
                      {capitalize(t('auth.buttons.cancel', 'cancel'))}
                    </Button>
                    <Button
                      className="button-primary"
                      size="small"
                      onClick={onSubmitForm}
                    >
                      <Icon path={mdiContentSaveOutline} size={1} className="mr-1" />
                      {capitalize(t('auth.buttons.save', 'save'))}
                    </Button>
                  </>
                ) : (
                  <Button
                    className="button-primary-outlined"
                    size="small"
                    onClick={() => onSetEditMode(true)}
                  >
                    <Icon path={mdiSquareEditOutline} size={1} className="mr-1" />
                    {capitalize(t('auth.buttons.edit', 'edit'))}
                  </Button>
                )}
                <DeleteButton
                  targetType="public_interface"
                  onSuccess={onSuccessDelete}
                  data={{ id: interfaceID }}
                >
                  <Button
                    className="button-danger-outlined"
                    size="small"
                  >
                    <Icon path={mdiTrashCanOutline} size={1} className="mr-1" />
                    {capitalize(t('auth.buttons.remove_interface', 'remove interface'))}
                  </Button>
                </DeleteButton>
              </Space>
            </Col>
          </Row>
          <hr className="my-4" />
          <Row gutter={[8, 0]}>
            <Col>
              <InterfaceIcon54Origins
                iconName={_.get(defaultInterfaceData, 'service_icon')}
                color={_.get(defaultInterfaceData, 'icon_color')}
                size={1.6}
              />
            </Col>
            <Col>
              <Row>
                <Col span={24}>
                  <Skeleton
                    loading={interfaceFetching || !defaultInterfaceData.id}
                    paragraph={false}
                    title={{
                      width: '50%',
                      style: {
                        margin: 0,
                        height: 24,
                      },
                    }}
                    active
                    round
                  >
                    <h3 className="header-primary m-0">
                      {_.get(defaultInterfaceData, 'service_name', '')}
                    </h3>
                  </Skeleton>
                </Col>
                <Col span={24} className="d-flex align-items-center mt-2">
                  <h5 className="header-primary">
                    ID:
                    {' '}
                    {interfaceID}
                  </h5>
                  <Button
                    className="button-primary-link ml-2"
                    size="small"
                    onClick={() => textToClipboard(interfaceID)}
                  >
                    <Icon path={mdiContentCopy} size={1} />
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
          <hr className="mt-4 mb-0" />
          <InterfaceForm
            form={interfaceForm}
            interfaceIcon={interfaceIcon}
            interfaceColor={interfaceColor}
            changeState={changeState}
            editMode={editMode}
            groupsForPI={localGroupsForPI}
            onSaveCallBackAndSetLocalActors={onSaveCallBackAndSetLocalActors}
          />
        </Card>
      </Spin>
    </IStickyBox>
  );
}

export default InterfaceInfo;

InterfaceInfo.propTypes = {
  interfaceID: PropTypes.number.isRequired,
  onSuccessDelete: PropTypes.func.isRequired,
  onSuccessUpdate: PropTypes.func,
};
