import _ from 'lodash';

export const isUIFetching = (state) => _.get(state, 'publicUI.interfacesList.fetching', false);

export const getInterfacesList = (state) => _.get(state, 'publicUI.interfacesList.interfaces', []);

export const getOpenInterfacesList = (state) => _.get(state, 'publicUI.interfacesList.openInterfaces', []);

export const getInterfaceData = (state) => _.get(state, 'publicUI.interfacesList.interfaceData', []);

export const getInterfaceFetching = (state) => _.get(state, 'publicUI.interfacesList.interfaceFetching', []);
