import {
  Modal,
  Progress,
  Row,
  Col,
} from 'antd';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  percentage,
  capitalize,
} from '../mainUtils';

function ProgressModal({
  visible,
  current,
  all,
}) {
  const { t } = useTranslation();
  const displayFormat = () => `${current}/${all}`;

  return (
    <Modal
      footer={null}
      closable={false}
      open={visible}
      centered
    >
      <Row>
        <Col span={24}>
          <h4 className="header-primary mb-2">
            {capitalize(t('auth.messages.actors_deletion', 'deletion process in progress, please wait...'))}
          </h4>
        </Col>
        <Col span={24}>
          <Progress
            percent={percentage(current, all)}
            format={displayFormat}
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default ProgressModal;

ProgressModal.propTypes = {
  all: PropTypes.number.isRequired,
  current: PropTypes.number.isRequired,
  visible: PropTypes.bool,
};
