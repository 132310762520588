import React from 'react';
import { Col, Row, Tag } from 'antd';
import { useSelector } from 'react-redux';
import { actorIsAdmin, actorIsRoot } from '../54origins/utils54origins';
import { getDefaultAdminGroupUUID } from './selectors';

function ActorListOfTags({ actor }) {
  const adminGroupUUID = useSelector(getDefaultAdminGroupUUID);

  const {
    actor_type: actorType,
    uinfo: {
      isRobot = false,
      group_name = '',
      groups = [],
    } = {},
  } = actor || {};

  return (
    <Row>
      {actorIsRoot(actor) && (
      <Col>
        <Tag className="tag-purple ml-2">
          ROOT
        </Tag>
      </Col>
      )}
      {(actorIsAdmin(actor) || groups?.includes(adminGroupUUID)) && (
      <Col>
        <Tag className="tag-red ml-2">
          ADMIN
        </Tag>
      </Col>
      )}
      {isRobot && <Col><Tag className="ml-2" color="volcano">ROBOT</Tag></Col>}
    </Row>
  );
}

export default ActorListOfTags;
