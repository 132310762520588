import PropTypes from 'prop-types';
import React, { memo } from 'react';
import { Tag } from 'antd';

function InternalOrExternalTag({
  internal,
  className,
}) {
  return (
    <Tag
      className={className}
      color={internal ? '#108ee9' : '#fa8c16'}
    >
      {internal ? 'Internal' : 'External'}
    </Tag>
  );
}

export default memo(InternalOrExternalTag);

InternalOrExternalTag.propTypes = {
  className: PropTypes.string,
  internal: PropTypes.bool,
};
