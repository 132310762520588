import _ from 'lodash';
import { AuthConstants } from '../../auth/constants';

const initialState = {
  fetching: false,
  documents: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case AuthConstants.GET_LIST_OF_POLICIES_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case AuthConstants.GET_LIST_OF_POLICIES_SUCCESS:
      return {
        ...state,
        fetching: false,
        documents: _.get(payload, 'security_policy', []),
      };

    case AuthConstants.GET_LIST_OF_POLICIES_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
};
