import _ from 'lodash';
import { actorIsRoot, actorIsRootOrAdmin } from '../../54origins/utils54origins';

export const getProfileInfo = (state) => _.get(state, 'profile.info.info');

export const isProfileFetching = (state) => _.get(state, 'profile.info.fetching', false);

export const getAccess = (state) => _.get(state, 'profile.info.info.access', false);

export const getMasqueradingEnabled = (state) => _.get(state, 'profile.info.masqueradingEnabled', false);

export const getMyPermissions = (state) => _.get(state, 'profile.info.permissions');

export const getMeIsBan = (state) => _.get(state, 'profile.info.isBan');

export const getMeIsMajorGroup = (state) => _.get(state, 'profile.info.majorGroup');

export const getMeIsRootOrAdmin = (state) => actorIsRootOrAdmin(_.get(state, 'profile.info.info.actor'));

export const getMeIsRoot = (state) => actorIsRoot(_.get(state, 'profile.info.info.actor'));
