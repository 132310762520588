import _ from 'lodash';
import { ActorsConstants } from '../constants/actionTypes';

const initialState = {
  fetching: false,
  actorInfo: {},
  error: '',
};

export default (state = initialState, action = {}) => {
  const {
    type,
    payload,
  } = action;

  switch (type) {
    case ActorsConstants.GET_ACTORS_DATA_REQUEST:
      return {
        ...state,
        fetching: true,
        actorInfo: {},
        error: '',
      };
    case ActorsConstants.GET_ACTORS_DATA_SUCCESS: {
      const { actor } = payload;

      return {
        ...state,
        fetching: false,
        actorInfo: actor,
        error: '',
      };
    }
    case ActorsConstants.GET_ACTORS_DATA_FAILURE:
      return {
        ...state,
        fetching: false,
        actorInfo: {},
        error: '',
      };
    case ActorsConstants.UPDATE_ACTOR_REQUEST:
      return {
        ...state,
        fetching: true,
        error: '',
      };
    case ActorsConstants.UPDATE_ACTOR_SUCCESS: {
      const { actor } = payload;

      return {
        ...state,
        fetching: false,
        actorInfo: actor,
      };
    }
    case ActorsConstants.UPDATE_ACTOR_FAILURE:
    case ActorsConstants.UPDATE_ACTOR_CANCEL:
      return {
        ...state,
        fetching: false,
        actorsData: state.actorInfo,
      };
    default:
      return state;
  }
};
