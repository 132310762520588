import PropTypes from 'prop-types';
import React, { useContext, useState } from 'react';
import {
  Alert, Col, Modal, Row, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import Icon from '@mdi/react';
import { mdiClose } from '@mdi/js';
import { useNavigate } from 'react-router-dom';
import { AuthHelper } from '../auth/utils';
import { ApiContext } from '../api/ApiContextProvider';
import { getAllUsersMap } from '../actors/selectors';
import { antNotification, capitalize } from '../mainUtils';

function MasqueradingModal({
  visible = false,
  onCancel,
  userUUID = '',
  users = [],
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { requestTurnOnMasquerading } = useContext(ApiContext);

  const allUsersMap = useSelector(getAllUsersMap);

  const [selectedUser, setSelectedUser] = useState(null);

  const reloadBrowserPage = () => {
    navigate(0);
  };

  const getUserName = () => {
    const userData = allUsersMap.get(userUUID);

    return `${_.get(userData, 'uinfo.first_name')} ${_.get(userData, 'uinfo.last_name')}`;
  };

  const userOptionRender = (user) => (
    <Select.Option
      value={user.value}
    >
      {user.label}
    </Select.Option>
  );

  const onSubmit = () => {
    const uuid = selectedUser || userUUID;
    requestTurnOnMasquerading(uuid)
      .then((response) => {
        const masqueradeSession = _.get(response, 'masquerade_session');
        const primarySession = _.get(response, 'primary_session');

        if (masqueradeSession && primarySession) {
          AuthHelper.masqueradingOn(masqueradeSession, primarySession).then(() => {
            antNotification.warning(capitalize(t(
              'auth.notifications.masquerading_on',
              'you entered masquerading mode',
            )));
            reloadBrowserPage();
          });
        }
      })
      .catch(() => antNotification.error(capitalize(t(
        'auth.notifications.masquerading_failed',
        'error switching to masquerading mode',
      ))));
    onCancel();
  };

  return (
    <Modal
      title={capitalize(t('auth.headers.masquerading', 'masquerading'))}
      okText={capitalize(t('auth.buttons.confirm', 'confirm'))}
      cancelText={capitalize(t('auth.buttons.cancel', 'cancel'))}
      okButtonProps={{ className: 'button-primary', disabled: !selectedUser && !userUUID }}
      cancelButtonProps={{ className: 'button-secondary-outlined' }}
      closeIcon={<Icon path={mdiClose} size={1.5} />}
      onOk={onSubmit}
      onCancel={onCancel}
      open={visible}
      destroyOnClose
      maskClosable
      centered
    >
      <Row gutter={[0, 16]}>
        {userUUID ? (
          <Col span={24} className="d-flex">
            <p className="p-secondary d-flex align-items-end mb-0">
              {capitalize(t('auth.headers.user', 'user'))}
              :
            </p>
            <h3 className="header-primary m-0 ml-2">
              {getUserName()}
            </h3>
          </Col>
        ) : (
          <Col span={24}>
            <p className="info-block-header mb-2">
              {capitalize(t(
                'auth.messages.masquerading_agree',
                'select the user whose session you want to replace',
              ))}
              :
            </p>
            <Select
              className="w-100"
              onChange={setSelectedUser}
              value={selectedUser}
              optionFilterProp="children"
              showSearch
              showArrow
              maxTagCount={20}
              allowClear
            >
              {users.map(userOptionRender)}
            </Select>
          </Col>
        )}
        <Col span={24}>
          <Alert
            message={capitalize(t('auth.notifications.warning', 'warning'))}
            description={capitalize(t('auth.messages.masquerading_warning'))}
            type="warning"
          />
        </Col>
      </Row>
    </Modal>
  );
}

export default MasqueradingModal;

MasqueradingModal.propTypes = {
  onCancel: PropTypes.func.isRequired,
  userUUID: PropTypes.string,
  users: PropTypes.array,
  visible: PropTypes.bool,
};
