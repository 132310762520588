import keyMirror from 'keymirror';

export const ActorsConstants = keyMirror({
  GET_ALL_ACTORS_REQUEST: null,
  GET_ALL_ACTORS_SUCCESS: null,
  GET_ALL_ACTORS_FAILURE: null,

  GET_ALL_GROUPS_REQUEST: null,
  GET_ALL_GROUPS_SUCCESS: null,
  GET_ALL_GROUPS_FAILURE: null,

  GET_DEFAULT_GROUPS_REQUEST: null,
  GET_DEFAULT_GROUPS_SUCCESS: null,
  GET_DEFAULT_GROUPS_FAILURE: null,

  UPDATE_ACTOR_REQUEST: null,
  UPDATE_ACTOR_SUCCESS: null,
  UPDATE_ACTOR_FAILURE: null,
  UPDATE_ACTOR_CANCEL: null,

  GET_ACTORS_DATA_REQUEST: null,
  GET_ACTORS_DATA_SUCCESS: null,
  GET_ACTORS_DATA_FAILURE: null,

  CHECK_LISTING_GROUP_REQUEST: null,
  CHECK_LISTING_GROUP_SUCCESS: null,
  CHECK_LISTING_GROUP_FAILURE: null,

  CREATE_ACTOR_REQUEST: null,
  CREATE_ACTOR_SUCCESS: null,
  CREATE_ACTOR_FAILURE: null,
  CREATE_ACTOR_CANCELED: null,

  GET_ACTORS_IN_GROUP_REQUEST: null,
  GET_ACTORS_IN_GROUP_SUCCESS: null,
  GET_ACTORS_IN_GROUP_FAILURE: null,

  REMOVE_ACTOR_REQUEST: null,
  REMOVE_ACTOR_SUCCESS: null,
  REMOVE_ACTOR_FAILURE: null,
  REMOVE_ACTOR_CANCEL: null,

  GET_ALL_USERS_REQUEST: null,
  GET_ALL_USERS_SUCCESS: null,
  GET_ALL_USERS_FAILURE: null,

  GET_ALL_SERVICES_REQUEST: null,
  GET_ALL_SERVICES_SUCCESS: null,
  GET_ALL_SERVICES_FAILURE: null,

  GET_DEFAULT_PERMS_REQUEST: null,
  GET_DEFAULT_PERMS_SUCCESS: null,
  GET_DEFAULT_PERMS_FAILURE: null,
  GET_DEFAULT_PERMS_CANCEL: null,

  GET_GROUPS_PERMS_REQUEST: null,
  GET_GROUPS_PERMS_SUCCESS: null,
  GET_GROUPS_PERMS_FAILURE: null,
  GET_GROUPS_PERMS_CANCEL: null,

  GET_ACTOR_PERMS_REQUEST: null,
  GET_ACTOR_PERMS_SUCCESS: null,
  GET_ACTOR_PERMS_FAILURE: null,
  GET_ACTOR_PERMS_CANCEL: null,

  GET_SYNC_SERVICES_INFO_REQUEST: null,
  GET_SYNC_SERVICES_INFO_SUCCESS: null,
  GET_SYNC_SERVICES_INFO_FAILURE: null,
  GET_SYNC_SERVICES_INFO_CANCEL: null,

  FORCE_SYNC_SERVICE_REQUEST: null,
  FORCE_SYNC_SERVICE_SUCCESS: null,
  FORCE_SYNC_SERVICE_FAILURE: null,
  FORCE_SYNC_SERVICE_CANCEL: null,

  FILTER_RESET_TAGS: null,

  SERVICE_SPECIFIC_ADMIN_REQUEST: null,
  SERVICE_SPECIFIC_ADMIN_SUCCESS: null,
  SERVICE_SPECIFIC_ADMIN_FAILURE: null,

  CHECK_SERVICE_KEY_PAIR_REQUEST: null,
  CHECK_SERVICE_KEY_PAIR_SUCCESS: null,
  CHECK_SERVICE_KEY_PAIR_FAILURE: null,
  SET_SERVICE_KEY_PAIR_UUID: null,

  CHECK_SERVICE_VERSION_REQUEST: null,
  CHECK_SERVICE_VERSION_SUCCESS: null,
  CHECK_SERVICE_VERSION_FAILURE: null,

  GET_REGISTERED_ON_SERVICES_REQUEST: null,
  GET_REGISTERED_ON_SERVICES_SUCCESS: null,
  GET_REGISTERED_ON_SERVICES_FAILURE: null,

  GET_UNSYNC_LOG_ACTOR_REQUEST: null,
  GET_UNSYNC_LOG_ACTOR_SUCCESS: null,
  GET_UNSYNC_LOG_ACTOR_FAILURE: null,
  UNSYNC_LOG_ACTOR_CLEAR: null,

  GET_UNSYNC_ACTORS_REQUEST: null,
  GET_UNSYNC_ACTORS_SUCCESS: null,
  GET_UNSYNC_ACTORS_FAILURE: null,
});
