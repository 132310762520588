import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Card, Col, Descriptions, Row, Space, Tag, Timeline,
} from 'antd';
import moment from 'moment';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { mdiArrowRight, mdiClockTimeEightOutline, mdiContentCopy } from '@mdi/js';
import Icon from '@mdi/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import IStickyBox from 'react-sticky-box';

import TagForLogComponent from './TagForLogComponent';

import { capitalize, textToClipboard } from '../../mainUtils';
import { getLocale } from '../../locale/selectors';
import { ApiContext } from '../../api/ApiContextProvider';
import { getAllGroupsMap, getAllUsersMap } from '../../actors/selectors';
import { getNameForActor } from '../../54origins/utils54origins';

function LogsInfoComponent({
  logData,
  currentViewInfoFromEntity,
  formatType,
}) {
  const { t } = useTranslation();
  const { requestGetLogs } = useContext(ApiContext);

  const locale = useSelector(getLocale) || 'en';
  const allUsersMap = useSelector(getAllUsersMap);
  const allGroupsMap = useSelector(getAllGroupsMap);

  const [logDataList, setLogDataList] = useState([]);

  const {
    created: logCreated,
    updater: logUpdater,
    updater: {
      uuid: logUpdaterUUID = '',
      deleted: logUpdaterDeleted = false,
    },
    actor: {
      uuid: logActorUUID,
    },
    updater_ip: logUpdaterIP,
  } = logData || {};

  const renderLogItem = (logItem, id) => {
    const {
      created,
      current,
      previous,
    } = logItem;

    if (!logItem.action) {
      return null;
    }

    const action = _.get(logItem, 'action', 'update');
    const fields = Object.keys(_.get(current, 'uinfo', {}));

    if (logItem === 'delete') {
      fields.push('delete');
    }

    if (_.isEmpty(fields)) {
      return null;
    }

    const whereOrBy = currentViewInfoFromEntity
      ? capitalize(t('auth.labels.by', 'by'))
      : capitalize(t('auth.labels.where', 'where'));

    const currentTime = logCreated === logItem.created;

    const dataActor = (type) => {
      const currentData = (data) => {
        switch (type) {
          case 'name':
            return getNameForActor(data);
          case 'type':
            return formatType(data);

          default:
            return null;
        }
      };

      switch (action) {
        case 'update':
        case 'delete':
          return currentData(_.get(logItem, `${currentViewInfoFromEntity ? 'updater' : 'actor'}`));
        case 'create':
          return currentData(_.get(logItem, `${currentViewInfoFromEntity ? 'updater' : 'current'}`));
        default:
          return null;
      }
    };

    return (
      <Timeline.Item
        dot={currentTime && <Icon path={mdiClockTimeEightOutline} color="#f5222d" size={1.2} />}
        key={`time_${created}_${id}`}
      >
        <Row gutter={[16, 10]} className="mt-3">
          <Col span={24} className="d-flex flex-wrap">
            <Tag className="tag-default-light">
              {moment(created).locale(locale).format('YYYY-MM-DD hh:mm:ss')}
            </Tag>
            <TagForLogComponent
              className="ml-4"
              typeOfTag={_.get(logItem, 'action')}
            />
            <div className="d-flex ml-2 colorTextGrey flex-nowrap">
              <div className="d-flex flex-nowrap">{`${whereOrBy}:`}</div>

              <span className="ml-2">
                {dataActor('type')}
              </span>

              <span className="d-flex ml-2 colorTextGrey">
                {dataActor('name')}
              </span>
            </div>
          </Col>
          <Col span={24}>
            <Descriptions
              size="small"
              column={1}
              bordered
            >
              {fields.map((item, id) => {
                let currentData = _.get(current, `uinfo.${item}`, []);
                let oldsData = _.get(previous, `uinfo.${item}`, []);
                let newCurrentDataArray = [];
                let oldDataArray = [];

                switch (item) {
                  case 'groups':
                    _.difference(currentData, oldsData)
                      .forEach((i) => {
                        if (allGroupsMap.has(i)) {
                          newCurrentDataArray.push(
                            _.get(allGroupsMap.get(i), 'uinfo.group_name', ''),
                          );
                        }
                      });

                    _.difference(oldsData, currentData)
                      .forEach((i) => {
                        if (allGroupsMap.has(i)) {
                          oldDataArray.push(
                            _.get(allGroupsMap.get(i), 'uinfo.group_name', ''),
                          );
                        }
                      });

                    break;
                  case 'users':
                    _.difference(currentData, oldsData)
                      .forEach((i) => {
                        if (allUsersMap.has(i)) {
                          const name = `${_.get(
                            allUsersMap.get(i),
                            'uinfo.first_name',
                            '',
                          )} ${_.get(
                            allUsersMap.get(i),
                            'uinfo.last_name',
                            '',
                          )}`;
                          newCurrentDataArray.push(name);
                        }
                      });

                    _.difference(oldsData, currentData)
                      .forEach((i) => {
                        if (allUsersMap.has(i)) {
                          const name = `${_.get(
                            allUsersMap.get(i),
                            'uinfo.first_name',
                            '',
                          )} ${_.get(
                            allUsersMap.get(i),
                            'uinfo.last_name',
                            '',
                          )}`;
                          oldDataArray.push(name);
                        }
                      });

                    break;
                  case 'partitions':
                    newCurrentDataArray = currentData.map((i) => i.partitions.name);
                    oldDataArray = oldsData.map((i) => i.partitions.name);
                    break;
                  case 'password':
                    currentData = capitalize(t(
                      'auth.headers.updated',
                      'updated',
                    ));
                    oldsData = '';
                    break;
                  case 'delete':
                    currentData = capitalize(t(
                      'auth.headers.actor_deleted',
                      'actor deleted',
                    ));
                    oldsData = '';
                    break;
                  default:
                    break;
                }

                return (
                  <Descriptions.Item
                    label={<span className="px-3 py-2">{item}</span>}
                    labelStyle={{ width: 120 }}
                    key={`${item}_${created}_${id + 1}`}
                    className="p-0"
                  >
                    <Row>
                      <Col span={11} className="bg-red px-3 py-2">
                        <div className="d-flex flex-wrap">
                          {(
                            _.isEmpty(newCurrentDataArray) && _.isEmpty(oldDataArray)
                          )
                          && (
                          <Space
                            size={[0, 8]}
                            direction="vertical"
                          >
                            {_.isArray(oldsData) ? (
                              oldsData.map((item) => (
                                <div
                                  className="w-100"
                                  key={`info_group_${item?.uuid}`}
                                >
                                  {allGroupsMap.has(item?.uuid) ? _.get(allGroupsMap.get(item?.uuid), 'uinfo.group_name') : item?.uuid}
                                  {item?.service_domain && item?.service_domain}
                                </div>
                              ))
                            ) : (
                              oldsData
                            )}
                          </Space>
                          )}
                        </div>
                      </Col>
                      <Col
                        span={2}
                        className="d-flex align-items-center justify-content-center"
                      >
                        <Icon path={mdiArrowRight} size={1} />
                      </Col>

                      <Col span={11} className="bg-green px-3 py-2">
                        <div className="d-flex flex-wrap">
                          {(_.isEmpty(newCurrentDataArray) && _.isEmpty(oldDataArray)) ? (
                            <Space
                              size={[0, 8]}
                              direction="vertical"
                            >
                              {_.isArray(currentData) ? (
                                currentData.map((item) => (
                                  <div
                                    className="w-100"
                                    key={`info_group_${item?.uuid}`}
                                  >
                                    {allGroupsMap.has(item?.uuid)
                                      ? _.get(allGroupsMap.get(item?.uuid), 'uinfo.group_name')
                                      : item?.uuid}
                                    {item?.service_domain && item?.service_domain}
                                  </div>
                                ))
                              ) : (
                                currentData
                              )}
                            </Space>
                          )

                            : (
                              <div className="d-flex flex-column align-item-start justify-content-start">
                                {!_.isEmpty(oldDataArray)
                              && (
                              <div className="d-flex align-item-start colorRed">
                                <span className="d-flex">
                                  {' '}
                                  {'- '}
                                </span>
                                <div className="d-flex align-item-center flex-wrap  colorRed">
                                  {oldDataArray.map((item, id) => (
                                    <span key={item + id} className="ml-2 ">
                                      {item}
                                    </span>
                                  ))}
                                </div>
                              </div>
                              )}

                                {!_.isEmpty(newCurrentDataArray)
                              && (
                              <div className="d-flex align-item-center">
                                <div className="d-flex align-item-center flex-wrap">
                                  {newCurrentDataArray.map((item, id) => (
                                    <span key={item + id} className="ml-2">
                                      {item}
                                    </span>
                                  ))}
                                </div>
                              </div>
                              )}

                              </div>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </Descriptions.Item>
                );
              })}
            </Descriptions>
          </Col>
        </Row>
      </Timeline.Item>
    );
  };

  const cardInfo = (
    name,
    checkDeleted,
    type,
    uuid,
    ip,
  ) => (
    <div className="d-flex flex-column  backgroundWhite ">
      <h3 className="header-primary mb-2">
        {name}
        {' '}
      </h3>

      {checkDeleted
        && (
        <div className="d-flex align-items-center mb-1">
          <label className="labelHeader">
            {capitalize(t('auth.headers.actor', 'actor'))}
            :
          </label>
          <Tag className="tag-red-light mb-1">
            {capitalize(t('auth.headers.deleted', 'deleted'))}
          </Tag>
        </div>
        )}
      <div className="d-flex">
        <label className="labelHeader">
          {capitalize(t('auth.headers.type', 'type'))}
          :
        </label>

        <div className="d-flex ml-2 colorTextGrey align-items-start">
          {type}
        </div>

      </div>

      <div className="d-flex align-items-start">
        <label className="labelHeader">
          UUID:
        </label>
        <span className="d-flex ml-2 colorTextGrey">
          {uuid}
        </span>
        <Button
          className="button-primary-link ml-2"
          size="small"
          onClick={() => textToClipboard(uuid)}
        >
          <Icon path={mdiContentCopy} size={1} />
        </Button>
      </div>

      <div className="d-flex align-items-start">
        <label className="labelHeader">
          {capitalize(t('auth.headers.ip_actor', 'IP actor'))}
          :
        </label>
        <span className="d-flex ml-2 colorTextGrey align-items-center">
          {ip}
          <Button
            className="button-primary-link ml-2"
            size="small"
            onClick={() => textToClipboard(ip)}
          >
            <Icon path={mdiContentCopy} size={1} />
          </Button>
        </span>
      </div>
      <hr />
    </div>
  );

  const initFunc = () => {
    const currentUuid = currentViewInfoFromEntity ? logActorUUID : logUpdaterUUID;

    const data = {
      [currentViewInfoFromEntity ? 'uuid' : 'updater']: currentUuid,
      order_by: 'created',
      reverse: true,
    };

    requestGetLogs(data).then((res) => {
      setLogDataList(res?.data);
    });
  };

  useEffect(() => {
    initFunc();
  }, [JSON.stringify(logData), currentViewInfoFromEntity]);

  return (
    <IStickyBox offsetTop={16} offsetBottom={16}>
      <Card
        className="card"
        loading={false}
      >
        {currentViewInfoFromEntity
          ? cardInfo(
            getNameForActor(_.get(_.head(logDataList) || {}, 'actor', '')),
            _.get(_.head(logDataList) || {}, 'action') === 'delete',
            formatType(_.head(logDataList)),
            _.get(_.head(logDataList) || {}, 'actor.uuid', ''),
            _.get(_.head(logDataList) || {}, 'updater_ip'),
          )
          : cardInfo(
            getNameForActor(logUpdater),
            logUpdaterDeleted,
            formatType(logUpdater),
            logUpdaterUUID,
            logUpdaterIP,
          )}

        {logDataList.length > 0 && (
          <Timeline>
            {logDataList?.map(renderLogItem)}
          </Timeline>
        )}
      </Card>
    </IStickyBox>

  );
}

LogsInfoComponent.propTypes = {
  currentViewInfoFromEntity: PropTypes.bool,
  formatAction: PropTypes.func,
  formatType: PropTypes.func,
  logData: PropTypes.object,
};

export default LogsInfoComponent;
