import { ActorsConstants } from '../constants/actionTypes';

const initialState = {
  fetching: false,
  registeredOnServicesActorsMap: new Map(),
  unSyncLogActorsMap: new Map(),
  unSyncGroupsMap: new Map(),
};

export default (state = initialState, action = {}) => {
  const {
    type,
    payload,
  } = action;
  switch (type) {
    case ActorsConstants.GET_REGISTERED_ON_SERVICES_REQUEST:
      return {
        ...state,
        fetching: true,
        registeredOnServicesActorsMap: new Map(),
      };

    case ActorsConstants.GET_REGISTERED_ON_SERVICES_SUCCESS:
      return {
        ...state,
        fetching: false,
        registeredOnServicesActorsMap: new Map(payload.actors.map((actor) => [actor.uuid, actor])),
      };

    case ActorsConstants.GET_REGISTERED_ON_SERVICES_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    case ActorsConstants.GET_UNSYNC_LOG_ACTOR_REQUEST:
    case ActorsConstants.UNSYNC_LOG_ACTOR_CLEAR:
      return {
        ...state,
        unSyncLogActorsMap: new Map(),
      };

    case ActorsConstants.GET_UNSYNC_LOG_ACTOR_SUCCESS:
      return {
        ...state,
        unSyncLogActorsMap: new Map(payload.actors.map((actor) => [actor.uuid, actor])),
      };

    case ActorsConstants.GET_UNSYNC_LOG_ACTOR_FAILURE:
      return {
        ...state,
      };

    case ActorsConstants.GET_UNSYNC_ACTORS_REQUEST:
      return {
        ...state,
      };

    case ActorsConstants.GET_UNSYNC_ACTORS_SUCCESS:
      return {
        ...state,
        unSyncGroupsMap: new Map(payload.actors.map((actor) => [actor.uuid, actor])),
      };

    case ActorsConstants.GET_UNSYNC_ACTORS_FAILURE:
      return {
        ...state,
      };

    default:
      return state;
  }
};

export const getRegisteredOnServicesDataMap = (state) => state?.actors?.actorsMapReducer?.registeredOnServicesActorsMap;

export const getUnSyncLogActorsDataMap = (state) => state?.actors?.actorsMapReducer?.unSyncLogActorsMap;

export const getUnSyncGroupsDataMap = (state) => state?.actors?.actorsMapReducer?.unSyncGroupsMap;
