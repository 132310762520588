import keyMirror from 'keymirror';


export const AuthConstants = keyMirror({
  AUTH_INFO_REQUEST: null,
  AUTH_INFO_SUCCESS: null,
  AUTH_INFO_FAILURE: null,

  GET_LIST_OF_POLICIES_REQUEST: null,
  GET_LIST_OF_POLICIES_SUCCESS: null,
  GET_LIST_OF_POLICIES_FAILURE: null,
});
