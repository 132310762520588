import PropTypes from 'prop-types';
import React from 'react';
import { Tag } from 'antd';

function ListOfPIForActor({
  publicInterfaces = [],
  className,
}) {
  return (
    <div className={className}>
      {publicInterfaces.map((item) => (
        <Tag
          color="cyan"
          className="mr-2"
        >
          {item.service_name}
        </Tag>
      ))}
    </div>
  );
}

export default ListOfPIForActor;

ListOfPIForActor.propTypes = {
  className: PropTypes.string,
  publicInterfaces: PropTypes.array,
};
