import React from 'react';
import { createRoot } from 'react-dom/client';

import 'moment/locale/ru';
import 'sanitize.css/sanitize.css';
import './fonts/noir-pro/styles.css';
import 'antd/dist/antd.css';
import './index.css';
import './styles/main.css';
import './styles/ant.scss';
import './styles/custom.css';
import './index.scss';
import './i18n';

import App from './app/App';

const container = document.getElementById('root');

if (container) {
  const root = createRoot(container);
  root.render(<App />);
}
