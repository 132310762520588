import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import {
  Col, Dropdown, Row,
} from 'antd';
import { useTranslation, withTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { mdiMenuDown } from '@mdi/js';
import Icon from '@mdi/react';
import en from '../images/en.svg';
import ru from '../images/ru.svg';
import { switchLanguage } from '../locale/actions';
import { capitalize } from '../mainUtils';
import { getLocale } from '../locale/selectors';

function LocaleSwitcher({ i18n }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const locale = useSelector(getLocale);

  const onSwitchLanguage = ({ key }) => {
    localStorage.setItem('locale', key);

    i18n.changeLanguage(key).then();
    dispatch(switchLanguage(key));
  };

  const langItems = [
    {
      label: capitalize(t('auth.locales.english', 'english')),
      src: en,
      lang: 'en',
    },
    {
      label: capitalize(t('auth.locales.russian', 'russian')),
      src: ru,
      lang: 'ru',
    },
  ];

  const fullLocale = locale === 'en' ? 'english' : 'russian';

  useEffect(() => {
    const userLang = localStorage.getItem('locale');

    if (userLang && userLang !== locale) {
      onSwitchLanguage(userLang);
    }
  }, []);

  const items = langItems.filter((item) => item?.lang !== locale).map((item) => {
    const {
      lang,
      label,
      src,
    } = item;

    return (
      {
        key: lang,
        label: (
          <Row className=" py-2">
            <Col>
              <img src={src} height={20} width={20} alt={lang} />
            </Col>
            <Col>
              <span className="pl-3">{label}</span>
            </Col>
          </Row>),
      }
    );
  });

  return (
    <div
      id="language_switcher"
      className="header-dropdown-wrapper h-100"
    >
      <Dropdown
        menu={{ items, onClick: onSwitchLanguage }}
        trigger={['click']}
        placement="bottom"
        className="h-100"
      >
        <div className="d-flex cursor-pointer align-items-center" style={{ userSelect: 'none' }}>
          <img
            src={locale === 'ru' ? ru : en}
            height={24}
            width={24}
            alt={locale}
          />
          <span className="ml-3" style={{ userSelect: 'none' }}>
            {capitalize(t(`auth.locales.${fullLocale}`, fullLocale))}
          </span>
          <Icon path={mdiMenuDown} size={1.5} />
        </div>
      </Dropdown>
    </div>
  );
}

export default withTranslation()(LocaleSwitcher);

LocaleSwitcher.propTypes = {
  i18n: PropTypes.object.isRequired,
};
