import PropTypes from 'prop-types';
import React, {
  useEffect, useState,
} from 'react';
import _ from 'lodash';
import {
  Tag, Checkbox,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import ListOfActorsBasic from './ListOfActorsBasic';
import TableTitleWrapper from '../../components/TableTitleWrapper';

import { capitalize } from '../../mainUtils';
import { getAllActorsWithUnifiedName, getAllGroups, getAllGroupsMap } from '../selectors';
import { getLocale } from '../../locale/selectors';
import { dateFormat54origins, getNameForActor } from '../../54origins/utils54origins';

function ColumnsWrapperForBasicActorList({
  getActorTypes,
  typeOfColumns,
  withCheckBox,
  additionalColumns = [],
  getSelectedActors,
  getListOfActorsAgain,
  doNotMakeRequest,
  outerActors,
  getPaginationAndOrderCallback,
}) {
  const { t } = useTranslation();

  const [selectedActors, setSelectedActors] = useState(new Set());

  const allActors = useSelector(getAllActorsWithUnifiedName);

  const allGroups = useSelector(getAllGroups);
  const allGroupsMap = useSelector(getAllGroupsMap);
  const locale = useSelector(getLocale) || 'en';

  const [orderRules, changeOrderRules] = useState({
    order_by_column: 'created',
    order_by_rule: 'desc',
  });

  const changeSort = (param, sortOrder) => {
    changeOrderRules({
      order_by_column: param,
      order_by_rule: sortOrder === 'ascend' ? 'asc' : 'desc',
    });
  };

  const onSelectActors = (value) => {
    if (value === 'all') {
      setSelectedActors((prev) => {
        if (prev.size !== allActors.length) {
          return new Set(allActors.map(({ uuid }) => uuid));
        }
        return new Set();
      });
    } else {
      setSelectedActors((prev) => {
        if (prev.has(value)) {
          prev.delete(value);
        } else {
          prev.add(value);
        }
        return new Set(prev);
      });
    }
  };

  const formatName = (cell, row) => {
    const isRoot = row.root;
    const isDefault = row.default;
    const tagName = isRoot ? 'root' : 'default';

    let tagNames = [];

    if (row.groups && row.groups.length > 0 && allGroups.length > 0) {
      tagNames = row.groups
        .map((uuid) => _.get(allGroupsMap.get(uuid), 'uinfo.group_name'))
        .filter((item) => ['BAN', 'ADMIN'].includes(item));
    }

    return (
      <div className="d-flex align-items-center">
        {getNameForActor(row)}
        {(isRoot || isDefault) && (
        <Tag className={`tag-${isRoot ? 'purple' : 'gray'} ml-2`}>
          {t(`auth.headers.${tagName}`, tagName).toUpperCase()}
        </Tag>
        )}
        {tagNames.map((tag) => (
          <Tag
            key={`default_tag_${row.uuid}_${tag}`}
            className={`tag-${tag === 'BAN' ? 'red' : 'volcano'} ml-2`}
          >
            {t(`auth.headers.${tag.toLowerCase()}`, tag)}
          </Tag>
        ))}
      </div>
    );
  };

  const formatType = (cell) => (
    <Tag className={`tag-${cell}`}>
      {capitalize(t(`auth.headers.${cell}`, cell))}
    </Tag>
  );

  const titleDeleteCheckBox = (
    <Checkbox
      onClick={(e) => e.stopPropagation()}
      indeterminate={selectedActors.size !== 0 && selectedActors.size !== allActors.length}
      checked={selectedActors.size === allActors.length}
      onChange={() => onSelectActors('all')}
    />
  );

  const formatDeleteCheckBox = (cell) => (
    <Checkbox
      onClick={(e) => e.stopPropagation()}
      onChange={() => onSelectActors(cell)}
      checked={selectedActors.has(cell)}
    />
  );

  const colCheckbox = {
    dataIndex: 'uuid',
    key: 'delete_checkbox',
    className: 'p-2 pl-4',
    title: titleDeleteCheckBox,
    render: formatDeleteCheckBox,
  };

  const colActorName = {
    dataIndex: ['uinfo'],
    key: 'name',
    className: 'p-2',
    // width: 100,
    title: (
      <TableTitleWrapper
        minWidth={200}
      >
        {capitalize(t('auth.headers.title', 'title'))}
      </TableTitleWrapper>
    ),
    sorter: (a, b, sortOrder) => changeSort('title', sortOrder),
    render: formatName,
  };

  const colActorType = {
    dataIndex: 'actor_type',
    key: 'type',
    title: (
      <TableTitleWrapper minWidth={10}>
        {capitalize(t('auth.headers.type', 'type'))}
      </TableTitleWrapper>
    ),
    sorter: (a, b, sortOrder) => changeSort('actor_type', sortOrder),
    className: 'p-2',
    align: 'left',
    render: formatType,
  };

  const colActorCreated = {
    dataIndex: 'created',
    key: 'created',
    title: (
      <TableTitleWrapper minWidth={120}>
        {capitalize(t('auth.headers.created', 'created'))}
      </TableTitleWrapper>
    ),
    className: 'p-2',
    textWrap: 'word-break',
    sorter: (a, b, sortOrder) => changeSort('created', sortOrder),
    render: (cell) => capitalize(dateFormat54origins(cell).dateMonthWithTime),
    defaultSortOrder: 'descend',
  };

  const colActorModify = {
    dataIndex: ['uinfo', 'date_updated'],
    key: 'date_updated',
    title: (
      <TableTitleWrapper minWidth={120}>
        {capitalize(t('auth.headers.modify', 'modified'))}
      </TableTitleWrapper>
    ),
    className: 'p-2',
    textWrap: 'word-break',
    // sorter: (a, b, sortOrder) => changeSort('created', sortOrder),
    render: (cell) => capitalize(dateFormat54origins(cell).dateMonthWithTime),
    defaultSortOrder: 'descend',
  };

  const columns = [
    colActorName,
    colActorType,
    colActorCreated,
    colActorModify,
  ];

  const servicesColumnsWithoutActorDates = [
    colActorName,
    colActorType,
    ...additionalColumns,
  ];

  const servicesColumns = [
    ...columns,
    ...additionalColumns,
  ];

  const servicesVersioningColumns = [
    {},
    ...columns,
    ...additionalColumns,
  ];

  const getTypes = () => {
    switch (typeOfColumns) {
      case 'servicesKeyPairs':
      case 'servicesVersions':
        return [
          'GET_ALL_ACTORS_REQUEST',
          'GET_ALL_ACTORS_SUCCESS',
          'GET_ALL_ACTORS_FAILURE',
        ];
      default:
        return null;
    }
  };

  const getColumns = () => {
    const addCheckBoxForColumns = (columnsParam) => (withCheckBox ? [colCheckbox, ...columnsParam] : columnsParam);

    switch (typeOfColumns) {
      case 'servicesSessions':
        return addCheckBoxForColumns(servicesColumnsWithoutActorDates);
      case 'servicesKeyPairs':
        return addCheckBoxForColumns(servicesColumns);
      case 'servicesVersions':
        return addCheckBoxForColumns(servicesVersioningColumns);
      case 'existActors':
        return addCheckBoxForColumns(servicesColumns);
      default:
        return addCheckBoxForColumns(columns);
    }
  };

  useEffect(() => {
    if (getSelectedActors) {
      getSelectedActors(selectedActors);
    }
  }, [selectedActors]);

  return (
    <ListOfActorsBasic
      columns={getColumns()}
      typesForActorsRequest={getTypes()}
      getActorTypes={getActorTypes}
      orderRules={orderRules}
      getListOfActorsAgain={getListOfActorsAgain}
      doNotMakeRequest={doNotMakeRequest}
      outerActors={outerActors}
      getPaginationAndOrderCallback={getPaginationAndOrderCallback}
    />
  );
}

export default ColumnsWrapperForBasicActorList;

ColumnsWrapperForBasicActorList.propTypes = {
  additionalColumns: PropTypes.array,
  doNotMakeRequest: PropTypes.bool,
  getActorTypes: PropTypes.func,
  getListOfActorsAgain: PropTypes.any,
  getPaginationAndOrderCallback: PropTypes.func,
  getSelectedActors: PropTypes.func,
  outerActors: PropTypes.array,
  typeOfColumns: PropTypes.string,
  withCheckBox: PropTypes.bool
}
