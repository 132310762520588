import _ from 'lodash';
import { CertificatesConstants } from '../constants/actionTypes';

const initialState = {
  fetching: false,
  map: new Map(),
  list: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case CertificatesConstants.GET_CERTIFICATES_REQUEST:
      return {
        ...state,
        fetching: true,
      };

    case CertificatesConstants.GET_CERTIFICATES_SUCCESS:
      const map = new Map([
        ...payload.map((item) => [_.get(item, 'id'), item]),
      ]);

      return {
        ...state,
        fetching: false,
        list: payload,
        map,
      };

    case CertificatesConstants.GET_CERTIFICATES_FAILURE:
      return {
        ...state,
        fetching: false,
      };

    default:
      return state;
  }
};
