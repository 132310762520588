import PropTypes from 'prop-types';
import React, {
  useContext, useEffect, useState,
} from 'react';
import { Modal } from 'antd';
import { useSelector } from 'react-redux';
import { capitalize } from 'lodash';

import { getActorsInfo } from '../../selectors';
import { dateFormat54origins, getNameForActor } from '../../../54origins/utils54origins';
import i18n from '../../../i18n';

import AntButtonWithMDI from '../../../components/AntButtonWithMDI';
import { ApiContext } from '../../../api/ApiContextProvider';
import { capitalizeAndTranslateMsg } from '../../../mainUtils';
import ColumnsWrapperForBasicActorList from '../ColumnsWrapperForBasicActorList';
import useURLParams from '../../../hooks/useURLParams';

const translates = {
  getExistingUsers: ['existing users', 'существующие юзеры'],
  p1: ['This interface presents all users that exist on the selected service', 'В данном интерфейсе представлены все юзеры, которые существуют на выбранном сервисе'],
  p2: [
    'Important: actors with the \'group\' and \'service\' types are synchronized with all services by default, so they won\'t be represented in this list',
    'Важно: экторы с типом \'group\' и \'service\' синхронизируются со всеми сервисами по умолчанию, поэтому они не будут представлены в данном списке',
  ],
};

const translateFromLocalDict = (key, isCapitalize = true) => {
  const translate = translates?.[key]?.[i18n.language === 'en' ? 0 : 1];
  return isCapitalize ? capitalize(translate) : translate;
};

function ExistingUsersOnServiceBtnModal({
  serviceUUID,
}) {
  const actorData = useSelector(getActorsInfo);

  const { clearSearchParams, getURLParams, setSearchParams } = useURLParams();

  const { modal } = getURLParams();

  const { requestGetExistingUsersOnService } = useContext(ApiContext);

  const [modalVisible, setModalVisible] = useState(false);
  const [actors, setActors] = useState([]);

  const getExistingUsers = (params) => {
    requestGetExistingUsersOnService({
      serviceUUID,
      ...params,
    }).then((res) => {
      setActors(res);
    });
  };

  const getPaginationAndOrderCallback = (paginationAndOrder) => {
    const { pageLimit, offset } = paginationAndOrder;

    if (pageLimit) {
      getExistingUsers({
        limit: pageLimit,
        offset,
      });
    }
  };

  const onCancel = () => {
    setModalVisible(false);
    clearSearchParams(['modal']);
  };

  const additionalColumns = [{
    dataIndex: 'uuid',
    key: 'type',
    title: 'Action',
    className: 'p-2',
    align: 'left',
    render: (cell) => (
      <AntButtonWithMDI
        className="button-primary-outlined"
        onClick={() => {
          setSearchParams({
            uuid: cell,
            modal: 'syncDashboard',
          });
        }}
        label="Sync dashboard"
      />
    ),
  }];

  useEffect(() => {
    if (modal === 'existOnService') {
      setModalVisible(true);
    } else {
      setModalVisible(false);
    }
  }, [modal]);

  return (
    <Modal
      title={`${translateFromLocalDict('syncDashboardFor')} ${getNameForActor(actorData)} (Modify actor on auth: ${dateFormat54origins(actorData?.uinfo?.date_updated).dateWithTime})`}
      open={modalVisible}
      width="70%"
      destroyOnClose
      onCancel={onCancel}
      footer={(
        <AntButtonWithMDI
          type="primary"
          onClick={onCancel}
          label={capitalizeAndTranslateMsg('54origins.verb.cancel', 'cancel')}
        />
      )}
    >
      <p>{translateFromLocalDict('p1')}</p>
      <p>{translateFromLocalDict('p2')}</p>
      <ColumnsWrapperForBasicActorList
        additionalColumns={additionalColumns}
        doNotMakeRequest
        typeOfColumns="existActors"
        outerActors={actors}
        getPaginationAndOrderCallback={getPaginationAndOrderCallback}
      />
    </Modal>
  );
}

export default ExistingUsersOnServiceBtnModal;

ExistingUsersOnServiceBtnModal.propTypes = {
  serviceUUID: PropTypes.string,
};
