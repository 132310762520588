// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2023.09.12 //Format YYYY|MM|DD

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { head } from 'lodash';

export const getGitVersion = () => {
  const isExperimental = VERSION?.indexOf('experimental');
  const getVersion = (separator) => head(VERSION?.split(separator));
  const getBranch = (separator) => head(BRANCH?.split(separator));
  const version = isExperimental ? getVersion('-experimental') : getVersion('-stable');
  const branch = BRANCH?.indexOf('master') !== -1 ? getBranch('-master') : getBranch('-dev');

  return `${version}-${branch}`;
};

function GitVersion54origins() {
  // const navigate = useNavigate();
  const navigate = useNavigate();
  const createFile = () => {
    const version = getGitVersion();

    const fileData = JSON.stringify({
      version,
    });

    const blob = new Blob([fileData], { type: 'text/plain' });

    saveAs(blob, version);
    navigate('/');
  };

  useEffect(() => {
    createFile();
  }, []);
}

export default GitVersion54origins;

export function VersionLabel() {
  return (
    <span>
      Version:
      {' '}
      {getGitVersion()}
    </span>
  );
}
