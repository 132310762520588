import PropTypes from 'prop-types';
import React from 'react';
import { Tag } from 'antd';
import Icon from '@mdi/react';
import { COLORS, PublicInterfaceIconsConstants54Origins } from '../../54origins/components/publicInterface54Origins/publicInterfaceIconsConstants54Origins';

function ColorSwitch({
  path,
  color,
  checked,
  onClick,
}) {
  return (
    <Tag
      className={`tag-${color} color-switcher ${checked ? 'checked' : ''}`}
      onClick={() => onClick(color)}
    >
      <Icon
        className={`icon-shadow-${color.toLowerCase()}`}
        path={PublicInterfaceIconsConstants54Origins[path]}
        color={COLORS[color]}
        size={1.8}
      />
    </Tag>
  );
}

export default ColorSwitch;

ColorSwitch.propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  path: PropTypes.string.isRequired,
};
