import PropTypes from 'prop-types';
import React, { useEffect, useContext, useState } from 'react';
import {
  Col, Row, Button, Input,
} from 'antd';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';

import { useSelector } from 'react-redux';
import TagsSettingList from '../../components/TagsSettingList';

import { capitalize } from '../../mainUtils';
import { ApiContext } from '../../api/ApiContextProvider';
import { getDefaultGroupNames } from '../selectors';

function ListOfGroupsAsTagsInModal({
  getSelectedTags,
  groupTagsOnActorsPage,
}) {
  const { t } = useTranslation();
  const defaultGroupNames = useSelector(getDefaultGroupNames);

  const [previousGroups, changePreviousGroups] = useState([]);
  const [localTags, changeLocalTags] = useState([]);
  const [currentPage, changePage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [totalTags, setTotalTags] = useState(0);

  const limit = 10;
  const offset = limit * (currentPage - 1);

  const {
    requestGetAllGroups,
  } = useContext(ApiContext);

  const getGroupsForTags = async (params) => {
    const groups = await requestGetAllGroups(params, []);

    const {
      actors = [],
      total = 0,
    } = groups;

    const checkActors = actors ?? [];

    setTotalTags(total);
    changePreviousGroups([...previousGroups, ...checkActors.map((item) => {
      const label = _.get(item, 'uinfo.group_name');
      let className = '';

      if (defaultGroupNames.includes(label)) {
        className = label;
      }

      return {
        key: 'uinfo.groups',
        value: item.uuid,
        label,
        className,
      };
    })]);

    changeLocalTags([{
      tags: [
        ...previousGroups,
        ...checkActors.map((item) => {
          const label = _.get(item, 'uinfo.group_name');
          let className = '';

          if (defaultGroupNames.includes(label)) {
            className = label;
          }

          return {
            key: 'uinfo.groups',
            value: item.uuid,
            label,
            className,
          };
        }),
      ],
      isRow: true,
      rowText: capitalize(t('auth.headers.groups', 'groups')),
      className: 'isGroups',
    }]);
  };

  const getMoreTags = async () => {
    await changePage(currentPage + 1);

    await getGroupsForTags({
      limit,
      offset,
      order_by_column: 'created',
      order_by_rule: 'asc',
      uuid__not: groupTagsOnActorsPage?.map((item) => item.uuid || item.value),
    });
  };

  const onSearchClick = () => {
    const data = {
      order_by_column: 'created',
      order_by_rule: 'asc',
    };

    if (searchValue) {
      data.search_data = {
        value: searchValue,
        ignore_case: true,
        fields: {
          base: ['uuid'],
          uinfo: ['group_name'],
        },
      };
    }

    getGroupsForTags(data);
  };

  useEffect(() => {
    getMoreTags();
  }, []);

  return (
    <>
      <Row>
        <Col span={24}>
          <Input.Search
            allowClear
            onSearch={onSearchClick}
            className="w-100"
            onChange={(e) => setSearchValue(e.target.value)}
            value={searchValue}
            placeholder={capitalize(t('auth.placeholders.search_actor', 'enter group name or UUID'))}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TagsSettingList
                    // defaultSelectedTags={selectedFilters}
            allTags={localTags}
            onSelectTags={getSelectedTags}
            id="unselectedGroups"
            small
          />
          {previousGroups.length !== totalTags && totalTags !== 0
            ? <Button type="link" onClick={getMoreTags}>show more</Button> : null}
        </Col>
      </Row>
    </>
  );
}

export default ListOfGroupsAsTagsInModal;

ListOfGroupsAsTagsInModal.propTypes = {
  getSelectedTags: PropTypes.func,
  groupTagsOnActorsPage: PropTypes.array,
};
