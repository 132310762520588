import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Form, Select } from 'antd';
import { capitalizeAndTranslateMsg } from '../../mainUtils';

function ChangeTypeOfUserBtn({
  changeTypeOfUserCallback,
  defaultUserType,
}) {
  const [localUserType, setLocalUserType] = useState(defaultUserType);

  const onChange = (value) => {
    setLocalUserType(value);

    if (changeTypeOfUserCallback) {
      changeTypeOfUserCallback(value);
    }
  };

  useEffect(() => {
    setLocalUserType(defaultUserType);
  }, [defaultUserType]);

  return (
    <Form.Item
      label={capitalizeAndTranslateMsg('auth.headers.user type', 'type of user')}
      name="typeOfUser"
    >
      <Select
        onChange={onChange}
      >
        <Select.Option
          value={localUserType === 'user' ? 'classic_user' : 'user'}
        >
          {localUserType === 'user' ? 'classic user' : 'user'}
        </Select.Option>
      </Select>
    </Form.Item>
  );
}

export default ChangeTypeOfUserBtn;

ChangeTypeOfUserBtn.propTypes = {
  changeTypeOfUserCallback: PropTypes.func,
  defaultUserType: PropTypes.string,
};
