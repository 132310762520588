import { ActorsConstants } from '../constants/actionTypes';


const initialState = {
  fetching: false,
  data: [],
  error: '',
  uuid: '',
};

export default (state = initialState, action = {}) => {
  const {
    type,
    payload
  } = action;
console.log(action)

  switch (type) {
    case ActorsConstants.CHECK_SERVICE_KEY_PAIR_REQUEST:
      return {
        ...state,
        fetching: true,
        data: [],
        error: '',
        // uuid: '',
      };

    case ActorsConstants.CHECK_SERVICE_KEY_PAIR_SUCCESS:
      return {
        ...state,
        fetching: false,
        data: payload,
        error: ''
      };

    case ActorsConstants.CHECK_SERVICE_KEY_PAIR_FAILURE:
      return {
        ...state,
        fetching: false,
        data: [],
        error: payload?.message
      };

    case ActorsConstants.SET_SERVICE_KEY_PAIR_UUID:
      return {
        ...state,
        uuid: payload
      };
    default:
      return state;
  }
};

export const getCheckedServicesKeyPairs = (state) => state?.actors?.servicesKeyPairs?.data || [];

export const getCheckedServicesKeyPairsUUID = (state) => state?.actors?.servicesKeyPairs?.uuid;

export const getCheckedServicesKeyPairsError = (state) => state?.actors?.servicesKeyPairs?.error;
