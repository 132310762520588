// THIS IS GLOBAL REACT COMPONENT FROM ECOSYSTEM54.
// IF YOU WANT TO CHANGE THIS FILE, PLEASE CONTACT THE MAIN FRONTEND DEVELOPER ON THE ECOSYSTEM54
// UPDATED 2023.08.25
import PropTypes from 'prop-types';
import React, { useReducer } from 'react';
import {
  Tooltip, Button, Modal, Alert, notification,
} from 'antd';
import { capitalize, get } from 'lodash';
import { useTranslation } from 'react-i18next';
import { mdiClose } from '@mdi/js';
import Icon from '@mdi/react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

import { urlAPI } from '../../api/api';
import { getCookie, setCookie } from '../utils54origins';

const TURN_ON_MASQUERADING_REQUEST = 'TURN_ON_MASQUERADING_REQUEST';
const TURN_ON_MASQUERADING_SUCCESS = 'TURN_ON_MASQUERADING_SUCCESS';
const TURN_ON_MASQUERADING_FAILURE = 'TURN_ON_MASQUERADING_FAILURE';

const SERVICE = 'Auth';
const SERVICE_URL = urlAPI;

function MasqueradingBtn54origins({
  masqueradingDisabled,
  actorUUID,
  btnLabel = 'M',
  btnSize = 'small',
  tooltipVisible,
  tooltipPlacement,
  className,
}) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [isModalVisible, setIsModalVisible] = useReducer((previous) => !previous, false);

  const reloadBrowserPage = () => {
    navigate(0);
  };

  const requestTurnOnMasquerading = (uuid) => new Promise((resolve, reject) => {
    dispatch({ type: TURN_ON_MASQUERADING_REQUEST });

    axios({
      method: 'post',
      url: `${SERVICE_URL}/masquerade/on/`,
      data: { actor_uuid: uuid },
      headers: {
        'Session-Token': getCookie(SERVICE),
      },

    })
      .then(({ data }) => {
        dispatch({ type: TURN_ON_MASQUERADING_SUCCESS });
        resolve(data);
      })
      .catch(({ error }) => {
        dispatch({ type: TURN_ON_MASQUERADING_FAILURE });
        reject(error);
      });
  });

  const masqueradingOn = (masqueradeSession, primarySession) => new Promise((resolve, reject) => {
    try {
      if (!getCookie(`${SERVICE}Primary`)) {
        setCookie(`${SERVICE}Primary`, primarySession, { path: '/' });
      }
      setCookie(SERVICE, masqueradeSession, { path: '/' });

      resolve();
    } catch (e) {
      reject();
    }
  });

  const onSubmit = () => {
    requestTurnOnMasquerading(actorUUID).then((response) => {
      const masqueradeSession = get(response, 'masquerade_session');
      const primarySession = get(response, 'primary_session');

      if (masqueradeSession && primarySession) {
        masqueradingOn(masqueradeSession, primarySession).then(() => {
          notification.warning(capitalize(t(
            'auth.notifications.masquerading_on',
            'you entered masquerading mode',
          )));
          navigate('/login');
          reloadBrowserPage();
        });
      }
    })
      .catch(() => notification.error(capitalize(t(
        'auth.notifications.masquerading_failed',
        'error switching to masquerading mode',
      ))));

    setIsModalVisible();
  };

  return (
    <>
      <Tooltip
        title={capitalize(t('wms.verb.masquerading', 'masquerading'))}
        visible={tooltipVisible}
        placement={tooltipPlacement}
      >
        <Button
          className={`button-warning-outlined ${className}`}
          size={btnSize}
          disabled={masqueradingDisabled}
          onClick={(e) => {
            e.stopPropagation();
            setIsModalVisible();
          }}
          onDoubleClick={(e) => e.stopPropagation()}
        >
          {btnLabel}
        </Button>
      </Tooltip>
      <Modal
        title={capitalize(t('wms.verb.masquerading', 'masquerading'))}
        footer={[
          <div className="d-flex">
            <Button className="ml-auto" key="back" onClick={setIsModalVisible}>
              {capitalize(t('wms.verb.cancel', 'cancel'))}
            </Button>
            <Button key="submit" type="primary" onClick={onSubmit}>
              {capitalize(t('wms.verb.confirm', 'confirm'))}
            </Button>
          </div>,
        ]}
        closeIcon={<Icon path={mdiClose} size={1.5} />}
        // onOk={onSubmit}
        onCancel={setIsModalVisible}
        visible={isModalVisible}
        destroyOnClose
        maskClosable
        centered
      >
        <Alert
          message={capitalize(t('wms.noun.warning', 'warning'))}
          description={capitalize('in masquerading mode, you will use the session of your chosen user')}
          type="warning"
        />
      </Modal>
    </>
  );
}

export default MasqueradingBtn54origins;

MasqueradingBtn54origins.propTypes = {
  actorUUID: PropTypes.string,
  masqueradingDisabled: PropTypes.bool,
  btnLabel: PropTypes.string,
  btnSize: PropTypes.string,
};
